export const bank = [
    {value: '광주은행', title: '광주은행'},
    {value: '기업은행', title: '기업은행'},
    {value: 'KB국민은행', title: 'KB국민은행'},
    {value: '카카오뱅크', title: '카카오뱅크'},
    {value: '하나은행', title: '하나은행'},
    {value: '신한은행', title: '신한은행'},
    {value: '농협', title: '농협'},
    {value: 'SC제일은행', title: 'SC제일은행'},
    {value: '우리은행', title: '우리은행'},
    {value: '한국시티은행', title: '한국시티은행'},
    {value: '경남은행', title: '경남은행'},
    {value: '대구은행', title: '대구은행'},
    {value: '부산은행', title: '부산은행'},
    {value: '전북은행', title: '전북은행'},
    {value: '제주은행', title: '제주은행'},
    {value: '수협', title: '수협'},
    {value: '한국산업은행', title: '한국산업은행'},
    {value: '한국수출입은행', title: '한국수출입은행'},
    {value: 'SBI저축은행', title: 'SBI저축은행'},
    {value: '애큐온저축은행', title: '애큐온저축은행'},
    {value: '키움YES저축은행', title: '키움YES저축은행'},
    {value: '푸른저축은행', title: '푸른저축은행'},
]
