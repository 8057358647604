import {ConstructionTemplate} from "../../components/templates/construction";
import styled from "styled-components";

export const Construction = () => {
    return (
        <ConstructionWrap>
            <ConstructionTemplate></ConstructionTemplate>
        </ConstructionWrap>
    )
};

const ConstructionWrap = styled.div`
    
`
