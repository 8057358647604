import styled from "styled-components";
import {ChangeEvent, ReactNode} from "react";

type props = {
    id?: string,
    trigger?: ReactNode,
    onInput(e: ChangeEvent<HTMLInputElement>): void
    accept?: string,
}

const FileInput = ({id, onInput, trigger, accept}: props) => {
    return (
        <FileInputWrap>
            {trigger}
            <InputWrap type={'file'} id={id} onInput={onInput} accept={accept}/>
        </FileInputWrap>
    )
}

const FileInputWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const InputWrap = styled.input`
  display: none;
`

export default FileInput;
