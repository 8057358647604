import styled from 'styled-components';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {closeModal, modalState} from "../../../recoil/modal";
import {ReactNode, useEffect} from "react";

const Modal = ({children}: {children?: ReactNode}) => {
    const setModal = useSetRecoilState(modalState);
    const {show, children: stateChildren} = useRecoilValue(modalState);

    useEffect(() => {
        const el = document.querySelector('body');
        if (el) el.style.overflow = show ? 'hidden' : '';
    }, [show]);

    if (!show) return (<></>);
    if (show && (!children && !stateChildren)) return (<></>);

    return (
        <ModalWrap>
            <ModalInnerWrap>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                     onClick={() => closeModal(setModal)}
                >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                </svg>
                {stateChildren && stateChildren}
                {children && children}
            </ModalInnerWrap>
        </ModalWrap>
    );
}

const ModalWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 40px;
`;

const ModalInnerWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px;

  position: relative;
  
  & > svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`

export default Modal;
