import styled from "styled-components";
import {LayoutTemplate} from "../../layout";
import {LayoutTitleTemplate} from "../../layout/title";
import {useNavigate} from "react-router-dom";
import Input, {inputThemes} from "../../../atoms/input";
import Button, {buttonThemes} from "../../../atoms/button";
import {colors} from "../../../../styles";
import {useCallback, useState} from "react";
import {useQueryClient} from "react-query";
import FileInput from "../../../molecules/fileInput";
import {useInsertConstruction} from "../../../../api/construction";
import {useUploadFile} from "../../../../api/file";
import IconInput from "../../../molecules/iconinput";
import {useSetRecoilState} from "recoil";
import {modalState, showModal} from "../../../../recoil/modal";
import {ConstructionAddModalTemplate} from "./Modal";
import CDatePickerRange from "../../../atoms/datepicker/pickerRange";
import {shapes} from "../../../../types";
import {Document, Page, pdfjs} from 'react-pdf';
import {formatDate} from "../../../../utils/formatDate";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ConstructionAddTemplate = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const setModal = useSetRecoilState(modalState);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [previewPdf, setPreviewPdf] = useState<string>('');
    const [previewImg, setPreviewImg] = useState<string>('');
    const [info, setInfo] = useState({
        userSeq: 0,
        title: '',
        interiorCompany: '',
        interiorConsumer: '',
        telephone: '',
        startDate: '',
        endDate: '',
        price: 0,
        imageUrl1: '',
        status: 1
    });
    const {mutate: mutateUploadFile} = useUploadFile();
    const {mutate: mutateInsertConstruction} = useInsertConstruction();

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    }, []);

    const onPushComma = useCallback((value: string | number) => {
        const numValue = value.toString().replaceAll(',', '');
        value = numValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return value;
    }, []);

    const onChangeInfo = useCallback((key: string, value: string | number) => {
        if (key === 'price') value = Number(value.toString().replaceAll(',', ''));

        setInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        const keys = Object.keys(info);
        for (let key of keys) {
            if (key === 'status' || key === 'imageUrl1') continue;

            if (!info[key as keyof typeof info]) {
                alert("모든 입력 값을 입력해주세요.");
                return false;
            }
        }

        if (!file) {
            alert("파일을 입력해주세요.");
            return false;
        }

        const formData = new FormData();
        formData.append('imageUrl1', file as File);

        mutateUploadFile(formData, {
            onSuccess: async ({data}) => {
                info.imageUrl1 = data.imageUrl1;
                mutateInsertConstruction(info, {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries(['construction']);
                        navigate('/construction')
                    },
                });
            },
        });

        // if (file) {
        //
        // } else {
        //     console.log(info);
        //     mutateInsertConstruction(info, {
        //         onSuccess: async () => {
        //             await queryClient.invalidateQueries(['construction']);
        //             navigate('/construction')
        //         },
        //     });
        // }
    }, [info, file]);

    return (
        <ConstructionAddWrap>
            <LayoutTemplate>
                <>
                    <LayoutTitleTemplate title={'현장등록'} isPriceShow={false}/>

                    <InputWrap>
                        <InputItemsWrap>
                            <InputItemWrap>
                                <InputImageItemHeaderWrap>
                                    <span>계약서 파일</span>
                                    <InputImageItemHeaderButtonWrap>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                             onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : null}
                                        >
                                            <path d="m15 18-6-6 6-6"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                             onClick={() => pageNumber < numPages ? setPageNumber(pageNumber + 1) : null}
                                        >
                                            <path d="m9 18 6-6-6-6"></path>
                                        </svg>
                                    </InputImageItemHeaderButtonWrap>
                                </InputImageItemHeaderWrap>

                                <FileInput id={'file1'}
                                           accept={'image/*,.pdf'}
                                           onInput={(e) => {
                                               const file = e?.target.files?.[0];

                                               if (file) {
                                                   const reader = new FileReader();
                                                   reader.onload = function (data) {
                                                       setPreviewPdf('');
                                                       setPreviewImg('');

                                                       if (file.type.indexOf('pdf') !== -1) {
                                                           setPageNumber(1);
                                                           setPreviewPdf(data?.target?.result as string);
                                                       } else {
                                                           setPreviewImg(data?.target?.result as string);
                                                           const image = document.querySelector("#preview") as HTMLImageElement;
                                                           if (image) image.src = data?.target?.result as string;
                                                       }
                                                   }

                                                   reader.readAsDataURL(file);
                                                   setFile(file);
                                               }
                                           }}
                                           trigger={
                                               <FileWrap htmlFor={'file1'}>
                                                   <PreviewImgWrap id={'preview'} alt={'preview'} $isShow={!!previewImg}/>

                                                   {
                                                       previewPdf &&
                                                       <Document file={previewPdf} onLoadSuccess={onDocumentLoadSuccess}>
                                                           <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
                                                       </Document>
                                                   }

                                                   <span>{file ? file?.name : '업로드할 파일을 올려주세요.'}</span>

                                                   {
                                                       !file &&
                                                       <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                                                           <path
                                                               d="M40.3333 69.6668H47.6667V54.3585L53.5333 60.2252L58.6667 55.0002L44 40.3335L29.3333 55.0002L34.5583 60.1335L40.3333 54.3585V69.6668ZM22 80.6668C19.9833 80.6668 18.2563 79.9482 16.819 78.5108C15.3817 77.0735 14.6642 75.3477 14.6667 73.3335V14.6668C14.6667 12.6502 15.3853 10.9232 16.8227 9.48584C18.26 8.0485 19.9858 7.33106 22 7.3335H51.3333L73.3333 29.3335V73.3335C73.3333 75.3502 72.6147 77.0772 71.1773 78.5145C69.74 79.9518 68.0142 80.6693 66 80.6668H22ZM47.6667 33.0002H66L47.6667 14.6668V33.0002Z"
                                                               fill="#DFDFDF"/>
                                                       </svg>
                                                   }
                                               </FileWrap>
                                           }/>
                            </InputItemWrap>
                        </InputItemsWrap>

                        <InputItemsWrap>
                            <InputItemWrap>
                                <span>현장명</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'현장명을 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('title', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>인테리어 소비자</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'인테리어 소비자를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('interiorConsumer', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>연락처</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'연락처를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('telephone', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>인테리어 업체</span>
                                <IconInput theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'인테리어 업체를 입력해주세요.'} disabled
                                           value={info.interiorCompany}
                                           width={'100%'} onChange={(e) => onChangeInfo('userSeq', e?.target.value || '')}
                                           icon={
                                               <svg onClick={() => showModal(setModal, {
                                                   children: <ConstructionAddModalTemplate onChangeInfo={onChangeInfo}/>
                                               })}
                                                    xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                                    stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                   <circle cx="11" cy="11" r="8"></circle>
                                                   <path d="m21 21-4.3-4.3"></path>
                                               </svg>
                                           }
                                />
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>공사 기간</span>
                                <CDatePickerRange selected={info.startDate ? new Date(info.startDate) : undefined}
                                                  startDate={info.startDate ? new Date(info.startDate) : undefined}
                                                  endDate={info.endDate ? new Date(info.endDate) : undefined}
                                                  onChange={(dates: [Date | null, Date | null]) => {
                                                      const [start, end] = dates;

                                                      if (!start && !end) {
                                                          onChangeInfo('startDate', '');
                                                          onChangeInfo('endDate', '');
                                                      } else {
                                                          onChangeInfo('startDate', formatDate({source: start, showTime: false}) || '');
                                                          onChangeInfo('endDate', formatDate({source: end, showTime: false}) || '');
                                                      }
                                                  }}
                                />
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>총 공사 금액</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'총 공사 금액을 입력해주세요.'}
                                       value={onPushComma(info.price)}
                                       width={'100%'} onChange={(e) => onChangeInfo('price', e?.target.value || '')}/>
                            </InputItemWrap>
                        </InputItemsWrap>
                    </InputWrap>

                    <Button width={'150px'} fontStyle={'bold24'} theme={buttonThemes.blue} shape={shapes.rectangle} onClick={onSubmit}>
                        등록하기
                    </Button>
                </>
            </LayoutTemplate>
        </ConstructionAddWrap>
    );
};

const ConstructionAddWrap = styled.div`

`

const InputWrap = styled.div`
  display: flex;
  gap: 20px;

  & ~ button {
    align-self: end;
  }
`

const InputItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`

const InputItemMultiWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
`

const InputItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  width: 100%;
  height: 100%;
  font-size: 24px;

  & input {
    font-size: 24px;
  }
`;

const InputImageItemHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const InputImageItemHeaderButtonWrap = styled.div`
  display: flex;
  gap: 10px;

  & svg {
    cursor: pointer;
  }
`

const FileWrap = styled.label`
  border: 1px solid ${colors.black};
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 20px;

  & > span {
    color: ${colors.gray}
  }

  & .react-pdf__Document {
    width: 100%;
  }

  & .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const PreviewImgWrap = styled.img<{ $isShow: boolean }>`
  width: 100%;
  display: ${props => props.$isShow ? 'block' : 'none'};
`;
