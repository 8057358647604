import {LoginTemplate} from "../../components/templates/login";
import styled from "styled-components";

export const Login = () => {
    return (
        <LoginWrap>
            <LoginTemplate></LoginTemplate>
        </LoginWrap>
    )
};

const LoginWrap = styled.div`
  background-image: linear-gradient(to top, rgba(0, 87, 255, 1), rgba(0, 87, 255, 0.56));
`
