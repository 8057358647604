import {colors, fontStyles, typography} from "../../../styles";
import styled from "styled-components";
import {useState, Dispatch, SetStateAction} from "react";

type props = {
    infoKey: string;
    items: itemProps[];
    placeholder?: string;
    $isOpen?: boolean;
    setInfo: Dispatch<SetStateAction<any>>;
    fontStyle?: fontStyles;
    value?: string | number;
}

type itemProps = {
    value: string | number,
    title: string,
}

type styleProps = Pick<props, '$isOpen' | 'fontStyle'>;

const Select = ({infoKey, setInfo, items, placeholder, fontStyle, value}: props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [select, setSelect] = useState<string | number>(value ? value : '');

    return (
        <SelectWrap fontStyle={fontStyle}>
            <SelectTitleWrap $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                {
                    select ? <span>{select}</span> : placeholder && <span>{placeholder}</span>
                }
            </SelectTitleWrap>

            {
                isOpen &&
                <SelectItemsWrap>
                    {
                        placeholder &&
                        <SelectItemWrap onClick={() => {
                            setIsOpen(false);
                            setSelect('');

                            setInfo((prev: any) => ({
                                ...prev,
                                [infoKey]: '',
                            }));
                        }}>{placeholder}</SelectItemWrap>
                    }

                    {
                        items && items.map((i, index) => {
                            return (
                                <SelectItemWrap key={index} $active={i.value === select} last={items.length === (index + 1)} onClick={() => {
                                    setIsOpen(false);
                                    setSelect(i.value);

                                    setInfo((prev: any) => ({
                                        ...prev,
                                        [infoKey]: i.value,
                                    }));
                                }}>
                                    {i.title}
                                </SelectItemWrap>
                            )
                        })
                    }
                </SelectItemsWrap>
            }
        </SelectWrap>
    );
}

const SelectWrap = styled.div<styleProps>`
  ${({fontStyle}) => (fontStyle ? typography[fontStyle] : '')};
  position: relative;
  color: ${colors.main};
  height: 100%;
`

const SelectTitleWrap = styled.span<styleProps>`
  background-color: ${colors.white};
  border-radius: ${({$isOpen}) => $isOpen ? '' : '16px'};
  border-top-right-radius: ${({$isOpen}) => $isOpen ? '16px' : '16px'};
  border-top-left-radius: ${({$isOpen}) => $isOpen ? '16px' : '16px'};
  border: 1px solid ${colors.main};
  border-bottom-width: ${({$isOpen}) => $isOpen ? 0 : '1px'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  white-space: nowrap;
  padding: 0 20px;
  height: 100%;
  cursor: pointer;
`

const SelectItemWrap = styled.span<{ $active?: boolean, last?: boolean }>`
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({$active}) => $active ? colors.sub1 : ''};
  color: ${({$active}) => $active ? colors.white : colors.main};
  border-bottom-left-radius: ${({last}) => last ? '16px' : ''};
  border-bottom-right-radius: ${({last}) => last ? '16px' : ''};
`

const SelectItemsWrap = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${colors.white};
  top: 100%;
  border: 1px solid ${colors.main}; 
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-width: 0;
`

export default Select;
