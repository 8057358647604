import axios from 'axios';
import {useMutation} from "react-query";

export const useUploadFile = () => {
    const api = async (data: FormData) => {
        return axios.post('/admin/api/v1/file', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}
