import styled from "styled-components";
import {useCallback, useMemo, useState} from "react";
import {LayoutTemplate} from "../../layout";
import {LayoutTitleTemplate} from "../../layout/title";
import Table from "../../../atoms/table";
import Tag, {tagThemes} from "../../../atoms/tag";
import Pagination from "../../../atoms/table/pagination";
import {useGetQueryString} from "../../../../utils/useGetQueryString";
import {useFindRequestsByConstructionSeq, useUpdateRequest} from "../../../../api/request";
import {requestTypes} from "../../../../types/request";
import {ConstructionDetailHeaderTemplate} from "./header";
import Input, {inputThemes} from "../../../atoms/input";
import Button, {buttonThemes} from "../../../atoms/button";
import {colors} from "../../../../styles";
import {useQueryClient} from "react-query";
import {bank} from "../../../../constants/bank";
import Select from "../../../atoms/select";
import {shapes, sizes} from "../../../../types";
import CDatePicker from "../../../atoms/datepicker/picker";
import {formatDate} from "../../../../utils/formatDate";

export const ConstructionDetailTemplate = () => {
    const {page} = useGetQueryString();
    const queryClient = useQueryClient();
    const {data: requestsData} = useFindRequestsByConstructionSeq();
    const {mutate} = useUpdateRequest();
    const [info, setInfo] = useState({
        seq: 0,
        name: '',
        accountHolder: '',
        accountBank: '',
        accountNumber: '',
        completeMemo: '',
        completeDate: ''
    });

    const onChangeInfo = useCallback((key: string, value: string | number) => {
        setInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    const onSubmitCancel = useCallback(() => {
        mutate({seq: info.seq, status: -1}, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['requestByConstruction']);
            },
        });
    }, [info]);

    const onSubmit = useCallback(() => {
        const keys = Object.keys(info);
        for (let key of keys) {
            if (!info[key as keyof typeof info]) {
                alert("모든 입력 값을 입력해주세요.");
                return false;
            }
        }

        mutate({...info, status: 2}, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['requestByConstruction']);
            },
        });
    }, [info]);

    const columns = useMemo(() => [
        {header: '번호', accessor: 'seq', width: '15%'},
        {header: '카테고리', accessor: 'type', width: '15%'},
        {header: '업체명', accessor: 'companyName', width: '15%'},
        {header: '신청일', accessor: 'insertDate', width: '15%'},
        {header: '연락처', accessor: 'telephone', width: '15%'},
        {header: '요청금액', accessor: 'price', width: '15%'},
        {header: '', accessor: 'status', width: '10%'},
    ], []);

    const data = useMemo(() => requestsData?.data.length > 0 ? requestsData?.data.map((i: requestTypes) => {
        const convertType = () => {
            if (i.type === 1) return '인건비';
            if (i.type === 2) return '자재비';
            if (i.type === 3) return '정산';
            if (i.type === 4) return '기타비용';
        }

        const convertStatus = () => {
            if (i.status === -1) return <Tag theme={tagThemes.red} size={sizes.lg}>취소</Tag>;
            if (i.status === 0) return <Tag theme={tagThemes.navy} size={sizes.lg}>대기</Tag>;
            if (i.status === 1) return <Tag theme={tagThemes.green} size={sizes.lg}>처리 완료</Tag>;
            if (i.status === 2) return <Tag theme={tagThemes.navy} size={sizes.lg}>입금 완료</Tag>;
        }

        return {
            ...i,
            type: convertType(),
            insertDate: i.insertDate?.slice(5, 16),
            price: `${i.price?.toLocaleString()}원`,
            status: convertStatus()
        };
    }) : [], [requestsData]);

    return (
        <ConstructionDetailWrap>
            <LayoutTemplate>
                <>
                    <LayoutTitleTemplate title={'공사현장상세'}/>
                    <ConstructionDetailHeaderTemplate />
                    <Table columns={columns} data={data} selectValue={info.seq} setSelectValue={(i) => setInfo({...i})}
                           onChildren={(i) => {
                               return (
                                   <InputWrap>
                                       <InputItemsWrap>
                                           <InputItemWrap>
                                               <span>이름</span>
                                               <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm} placeholder={'이름을 입력해주세요.'}
                                                      value={info.name}
                                                      width={'100%'} onChange={(e) => onChangeInfo('name', e?.target.value || '')}/>
                                           </InputItemWrap>

                                           <InputItemWrap>
                                               <span>예금주</span>
                                               <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                                      value={info.accountHolder}
                                                      placeholder={'예금주 이름을 입력해주세요.'} width={'100%'}
                                                      onChange={(e) => onChangeInfo('accountHolder', e?.target.value || '')}/>
                                           </InputItemWrap>

                                           <InputItemWrap>
                                               <span>계좌번호</span>

                                               <InputItemMultiWrap>
                                                   <Select infoKey={'accountBank'} setInfo={setInfo} items={bank} placeholder={'은행선택'} value={i.accountBank}/>
                                                   <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                                          value={info.accountNumber}
                                                          placeholder={'계좌번호를 입력해주세요.'} width={'100%'}
                                                          onChange={(e) => onChangeInfo('accountNumber', e?.target.value || '')}/>
                                               </InputItemMultiWrap>
                                           </InputItemWrap>

                                           <InputItemWrap>
                                               <span>처리날짜</span>
                                               <CDatePicker selected={info.completeDate ? new Date(info.completeDate) : undefined}
                                                            onChange={(date) => onChangeInfo('completeDate', formatDate({source: date, showTime: true}))}
                                                            size={sizes.sm} />
                                           </InputItemWrap>
                                       </InputItemsWrap>

                                       <InputItemWrap>
                                           <span>처리내용</span>
                                           <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.lg}
                                                  value={info.completeMemo}
                                                  placeholder={'처리내용을 입력해주세요.'}
                                                  width={'100%'} onChange={(e) => onChangeInfo('completeMemo', e?.target.value || '')}/>
                                       </InputItemWrap>

                                       <ButtonItemWrap>
                                           <Button width={'150px'} fontStyle={'medium24'} theme={buttonThemes.border} shape={shapes.rectangle}
                                                   onClick={onSubmitCancel}>
                                               취소하기
                                           </Button>

                                           <Button width={'150px'} fontStyle={'medium24'} theme={buttonThemes.blue} shape={shapes.rectangle}
                                                   onClick={onSubmit}>
                                               처리완료
                                           </Button>
                                       </ButtonItemWrap>
                                   </InputWrap>
                               )
                           }}
                    >
                    </Table>
                    {
                        requestsData && <Pagination count={requestsData?.count} page={Number(page)}/>
                    }
                </>
            </LayoutTemplate>
        </ConstructionDetailWrap>
    );
};

const ConstructionDetailWrap = styled.div`

`


const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px;
  align-items: end;
`

const InputItemsWrap = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(4, 1fr);
`

const InputItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  width: 100%;

  & > span {
    color: ${colors.main}
  }

  & input {
    font-size: 16px;
  }
`;

const InputItemMultiWrap = styled.div`
  display: flex;
  gap: 10px;
`

const ButtonItemWrap = styled.div`
  display: flex;
  gap: 20px;
`
