import {useCallback} from "react";
import axios from "axios";
import {useQuery} from "react-query";

export const useFindDashboardCount = () => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/dashboard`);
    }, []);

    return useQuery(
        ['dashboardCount'],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
