import {ConstructionDetailTemplate} from "../../components/templates/construction/detail";
import styled from "styled-components";

export const ConstructionDetail = () => {
    return (
        <ConstructionDetailWrap>
            <ConstructionDetailTemplate></ConstructionDetailTemplate>
        </ConstructionDetailWrap>
    )
};

const ConstructionDetailWrap = styled.div`
    
`
