export const sizes = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
} as const;
export type sizes = typeof sizes[keyof typeof sizes];

export const shapes = {
    normal: 'normal',
    rectangle: 'rectangle'
} as const;
export type shapes = typeof shapes[keyof typeof shapes];
