import {useNavigate} from "react-router-dom";
import {useGetQueryString} from "../../../../utils/useGetQueryString";
import {useFindUsers} from "../../../../api/user";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState} from "../../../../recoil/modal";
import {useMemo, useState} from "react";
import {userTypes} from "../../../../types/user";
import IconInput from "../../../molecules/iconinput";
import {inputThemes} from "../../../atoms/input";
import Table from "../../../atoms/table";
import Pagination from "../../../atoms/table/pagination";
import styled from "styled-components";
import {colors} from "../../../../styles";
import {sizes} from "../../../../types";

export const ConstructionAddModalTemplate = ({onChangeInfo}: { onChangeInfo(key: string, value: string | number): void }) => {
    const navigate = useNavigate();
    const {page} = useGetQueryString();
    const {data: userData} = useFindUsers();
    const setModal = useSetRecoilState(modalState);
    const [search, setSearch] = useState('');

    const columns = useMemo(() => [
        {header: '번호', accessor: 'seq', width: '10%'},
        {header: '아이디', accessor: 'id', width: '30%'},
        {header: '업체명', accessor: 'companyName', width: '30%'},
        {header: '연락처', accessor: 'telephone', width: '30%'},
    ], []);

    const data = useMemo(() => userData?.data.length > 0 ? userData?.data.map((i: userTypes) => {
        return {
            ...i,
        };
    }) : [], [userData]);

    return (
        <ConstructionAddModalWrap>
            <h1>업체를 선택해주세요.</h1>
            <IconInput placeholder={'검색어를 입력해주세요.'} fontStyle={'medium16'} size={sizes.sm} theme={inputThemes.white} width={'400px'}
                       onChange={(e) => setSearch(e?.target.value || '')}
                       icon={
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                               <circle cx="11" cy="11" r="8"></circle>
                               <path d="m21 21-4.3-4.3"></path>
                           </svg>
                       }
                       onClick={() => navigate(`?page=${page || 1}&search=${search}`)}
            />
            <Table columns={columns} data={data} onClick={(i) => {
                onChangeInfo('userSeq', i.seq);
                onChangeInfo('interiorCompany', i.companyName);
                closeModal(setModal);
            }}/>
            {
                userData && <Pagination count={userData?.count} page={Number(page)} search={search}/>
            }
        </ConstructionAddModalWrap>
    )
}

const ConstructionAddModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;

  & > h1 {
    padding-bottom: 20px;
    border-bottom: 4px solid ${colors.main};
    max-width: 450px;
  }
`;

