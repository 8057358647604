import {ConstructionAddTemplate} from "../../components/templates/construction/add";
import styled from "styled-components";

export const ConstructionAdd = () => {
    return (
        <ConstructionAddWrap>
            <ConstructionAddTemplate></ConstructionAddTemplate>
        </ConstructionAddWrap>
    )
};

const ConstructionAddWrap = styled.div`
    
`
