import styled from 'styled-components';
import {colors, fontStyles, typography} from "../../../styles";
import {ChangeEvent, ReactNode} from "react";
import Input, {inputThemes} from "../../atoms/input";
import {shapes, sizes} from "../../../types";

type props = {
    width?: string;
    theme?: inputThemes;
    shape?: shapes;
    size?: sizes;
    value?: string | number;
    placeholder?: string;
    flex?: number;
    fontStyle?: fontStyles;
    icon?: ReactNode;
    disabled?: boolean;
    onChange?(e?:ChangeEvent<HTMLInputElement>): void;
    onClick?(): void;
}

const IconInput = ({width, theme, shape, size, placeholder, fontStyle, flex, icon, value, disabled, onChange, onClick}: props) => {
    return (
        <IconInputWrap width={width}>
            <Input
                value={value}
                width={'100%'}
                theme={theme}
                shape={shape}
                disabled={disabled}
                size={size}
                placeholder={placeholder}
                flex={flex}
                onChange={onChange}
                fontStyle={fontStyle}
            />
            {
                icon &&
                <IconInputItemWrap onClick={onClick}>
                    {icon}
                </IconInputItemWrap>
            }
        </IconInputWrap>
    )
};

const IconInputWrap = styled.div<props>`
  ${({width}) => (width ? `width: ${width}` : '')};
  position: relative;
`;

const IconInputItemWrap = styled.span`
  position: absolute;
  background-color: ${colors.main};
  bottom: calc(50% - 16px);
  right: 16px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default IconInput;
