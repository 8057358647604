import styled from "styled-components";
import {LayoutTemplate} from "../../layout";
import {LayoutTitleTemplate} from "../../layout/title";
import {useNavigate} from "react-router-dom";
import Input, {inputThemes} from "../../../atoms/input";
import Button, {buttonThemes} from "../../../atoms/button";
import {colors} from "../../../../styles";
import {useCallback, useState} from "react";
import {useIdCheck, useInsertUser} from "../../../../api/user";
import {useQueryClient} from "react-query";
import Select from "../../../atoms/select";
import {bank} from "../../../../constants/bank";
import FileInput from "../../../molecules/fileInput";
import {useUploadFile} from "../../../../api/file";
import {regex} from "../../../../constants/patterns";
import {shapes} from "../../../../types";
import {Document, Page, pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const UserAddTemplate = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [idCheck, setIdCheck] = useState(false);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [previewPdf, setPreviewPdf] = useState<string>('');
    const [previewImg, setPreviewImg] = useState<string>('');
    const [info, setInfo] = useState({
        id: '',
        password: '',
        accountBank: '',
        accountNumber: '',
        businessNumber: '',
        name: '',
        telephone: '',
        companyName: '',
        companyTelephone: '',
        companyAddress: '',
        businessFileUrl: ''
    });
    const {mutate: idCheckMutate} = useIdCheck();
    const {mutate: uploadFile} = useUploadFile();
    const {mutate: insertUserMutate} = useInsertUser();

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    }, []);

    const onChangeInfo = useCallback((key: string, value?: string | number | File) => {
        setInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        const keys = Object.keys(info);
        for (let key of keys) {
            if (key === 'businessFileUrl') continue;

            if (!info[key as keyof typeof info]) {
                alert("모든 입력 값을 입력해주세요.");
                return false;
            }
        }

        if (!file) {
            alert("파일을 입력해주세요.");
            return false;
        }

        const formData = new FormData();
        formData.append('businessFileUrl', file as File);

        uploadFile(formData, {
            onSuccess: async ({data}) => {
                info.businessFileUrl = data.businessFileUrl;
                insertUserMutate(info, {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries(['user']);
                        navigate('/user')
                    },
                });
            },
        })
    }, [info, file]);

    const onSubmitCheck = useCallback(() => {
        return idCheck;
    }, [info]);

    const onIdCheck = useCallback(() => {
        idCheckMutate(info, {
            onSuccess: () => {
                setIdCheck(true);
                alert('사용 가능한 아이디 입니다.');
            }
        });
    }, [info]);

    return (
        <UserAddWrap>
            <LayoutTemplate>
                <>
                    <LayoutTitleTemplate title={'회원등록'} isPriceShow={false}/>

                    <InputWrap>
                        <InputItemsWrap>
                            <InputItemWrap>
                                <span>아이디</span>

                                <InputItemMultiWrap>
                                    <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'아이디를 입력해주세요.'}
                                           width={'100%'} onChange={(e) => {
                                        setIdCheck(false);
                                        onChangeInfo('id', e?.target.value || '');
                                    }}/>
                                    <Button width={'200px'} fontStyle={'bold24'} theme={buttonThemes.blue} shape={shapes.rectangle}
                                            onClick={onIdCheck} $isAble={regex.id.test(info.id)}>
                                        중복검사
                                    </Button>
                                </InputItemMultiWrap>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>비밀번호</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'비밀번호를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('password', e?.target.value || '')}/>
                            </InputItemWrap>

                            {/*<InputItemWrap>*/}
                            {/*    <span>비밀번호 확인</span>*/}
                            {/*    <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'비밀번호 확인을 입력해주세요.'}*/}
                            {/*           width={'100%'} onChange={(e) => onChangeInfo('passwordCheck', e?.target.value || '')}/>*/}
                            {/*</InputItemWrap>*/}

                            <InputItemWrap>
                                <span>계좌 정보</span>

                                <InputItemMultiWrap>
                                    <Select infoKey={'accountBank'} setInfo={setInfo} items={bank} placeholder={'은행선택'} fontStyle={'bold24'}/>
                                    <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'계좌번호를 입력해주세요.'}
                                           width={'100%'} onChange={(e) => onChangeInfo('accountNumber', e?.target.value || '')}/>
                                </InputItemMultiWrap>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>사업자 등록 번호</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'사업자 등록 번호를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('businessNumber', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <InputImageItemHeaderWrap>
                                    <span>사업자 등록증</span>
                                    <InputImageItemHeaderButtonWrap>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                             onClick={() => pageNumber > 1 ? setPageNumber(pageNumber - 1) : null}
                                        >
                                            <path d="m15 18-6-6 6-6"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                             onClick={() => pageNumber < numPages ? setPageNumber(pageNumber + 1) : null}
                                        >
                                            <path d="m9 18 6-6-6-6"></path>
                                        </svg>
                                    </InputImageItemHeaderButtonWrap>
                                </InputImageItemHeaderWrap>

                                <FileInput id={'file1'}
                                           accept={'image/*,.pdf'}
                                           onInput={(e) => {
                                               const file = e?.target.files?.[0];

                                               if (file) {
                                                   const reader = new FileReader();
                                                   reader.onload = function (data) {
                                                       setPreviewPdf('');
                                                       setPreviewImg('');

                                                       if (file.type.indexOf('pdf') !== -1) {
                                                           setPreviewPdf(data?.target?.result as string);
                                                       } else {
                                                           setPreviewImg(data?.target?.result as string);
                                                           const image = document.querySelector("#preview") as HTMLImageElement;
                                                           if (image) image.src = data?.target?.result as string;
                                                       }
                                                   }

                                                   reader.readAsDataURL(file);
                                                   setFile(file);
                                               }
                                           }}
                                           trigger={
                                               <FileWrap htmlFor={'file1'}>
                                                   <PreviewImgWrap id={'preview'} alt={'preview'} $isShow={!!previewImg}/>

                                                   {
                                                       previewPdf &&
                                                       <Document file={previewPdf} onLoadSuccess={onDocumentLoadSuccess}>
                                                           <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
                                                       </Document>
                                                   }

                                                   <span>{file ? file?.name : '업로드할 파일을 올려주세요.'}</span>

                                                   {
                                                       !file &&
                                                       <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                                                           <path
                                                               d="M40.3333 69.6668H47.6667V54.3585L53.5333 60.2252L58.6667 55.0002L44 40.3335L29.3333 55.0002L34.5583 60.1335L40.3333 54.3585V69.6668ZM22 80.6668C19.9833 80.6668 18.2563 79.9482 16.819 78.5108C15.3817 77.0735 14.6642 75.3477 14.6667 73.3335V14.6668C14.6667 12.6502 15.3853 10.9232 16.8227 9.48584C18.26 8.0485 19.9858 7.33106 22 7.3335H51.3333L73.3333 29.3335V73.3335C73.3333 75.3502 72.6147 77.0772 71.1773 78.5145C69.74 79.9518 68.0142 80.6693 66 80.6668H22ZM47.6667 33.0002H66L47.6667 14.6668V33.0002Z"
                                                               fill="#DFDFDF"/>
                                                       </svg>
                                                   }
                                               </FileWrap>
                                           }/>
                            </InputItemWrap>
                        </InputItemsWrap>

                        <InputItemsWrap>
                            <InputItemWrap>
                                <span>대표자</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'대표자를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('name', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>대표 연락처</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'대표 연락처를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('telephone', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>업체명</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'업체명을 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('companyName', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>업체 연락처</span>

                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'업체 연락처를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('companyTelephone', e?.target.value || '')}/>
                            </InputItemWrap>

                            <InputItemWrap>
                                <span>업체 주소</span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} placeholder={'업체 주소를 입력해주세요.'}
                                       width={'100%'} onChange={(e) => onChangeInfo('companyAddress', e?.target.value || '')}/>
                            </InputItemWrap>
                        </InputItemsWrap>
                    </InputWrap>

                    <Button width={'150px'} fontStyle={'bold24'} theme={buttonThemes.blue} shape={shapes.rectangle} onClick={onSubmit}
                            $isAble={onSubmitCheck()}>
                        등록완료
                    </Button>
                </>
            </LayoutTemplate>
        </UserAddWrap>
    );
};

const UserAddWrap = styled.div`

`

const InputWrap = styled.div`
  display: flex;
  gap: 20px;

  & ~ button {
    align-self: end;
  }
`

const InputItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`

const InputItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  width: 100%;
  font-size: 24px;

  & input {
    font-size: 24px;
  }
`;

const InputImageItemHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const InputImageItemHeaderButtonWrap = styled.div`
  display: flex;
  gap: 10px;

  & svg {
    cursor: pointer;
  }
`

const InputItemMultiWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  
  & > div {
    width: 100%;
  }
`

const FileWrap = styled.label`
  border: 1px solid ${colors.black};
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > span {
    color: ${colors.gray}
  }

  & .react-pdf__Document {
    width: 100%;
  }

  & .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

const PreviewImgWrap = styled.img<{$isShow: boolean}>`
  width: 100%;
  display: ${props => props.$isShow ? 'block' : 'none'};
`;

