import {useCallback, useState} from "react";
import styled from "styled-components";
import {colors, fontStyles, typography} from "../../../styles";
import {sizes} from "../../../types";

type props = {
    value?: string,
    onClick?(): void
    size?: sizes,
    fontStyle?: fontStyles
}

export const CDatePickerInput = ({value, onClick, size, fontStyle}: props) => {
    const [show, setShow] = useState(false);

    const onShow = useCallback(() => {
        if (!show && onClick) onClick();
        setShow(!show);
    }, [show]);

    return (
        <SizeDatePickerInputWrap size={size} fontStyle={fontStyle}>
            {value}
            <DatePickerInputIconWrap onClick={onShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                     stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                    <line x1="16" x2="16" y1="2" y2="6"></line>
                    <line x1="8" x2="8" y1="2" y2="6"></line>
                    <line x1="3" x2="21" y1="10" y2="10"></line>
                </svg>
            </DatePickerInputIconWrap>
        </SizeDatePickerInputWrap>
    )
};

const DatePickerInputWrap = styled.div<Pick<props, 'fontStyle'>>`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 0 10px;
  ${({fontStyle}) => (fontStyle ? typography[fontStyle] : '')}
`

const SizeDatePickerInputWrap = styled(DatePickerInputWrap)<Pick<props, 'size'>>`
  ${({size}) => {
    switch (size) {
        case sizes.sm:
            return `
            height: 40px;
          `
        default:
            return `
            height: 72px;
        `;
    }
}};
`

const DatePickerInputIconWrap = styled.div`
  position: absolute;
  background-color: ${colors.main};
  bottom: calc(50% - 16px);
  right: 16px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
