import styled from "styled-components";
import {useMemo, useState} from "react";
import {LayoutTemplate} from "../layout";
import {LayoutTitleTemplate} from "../layout/title";
import {inputThemes} from "../../atoms/input";
import Table from "../../atoms/table";
import {requestTypes} from "../../../types/request";
import Tag, {tagThemes} from "../../atoms/tag";
import Pagination from "../../atoms/table/pagination";
import IconInput from "../../molecules/iconinput";
import {useNavigate} from "react-router-dom";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {useFindConstructions} from "../../../api/construction";
import {constructionTypes} from "../../../types/construction";
import Button, {buttonThemes} from "../../atoms/button";
import {shapes, sizes} from "../../../types";

export const ConstructionTemplate = () => {
    const navigate = useNavigate();
    const {page} = useGetQueryString();
    const {data: constructionData} = useFindConstructions();
    const [search, setSearch] = useState('');

    const columns = useMemo(() => [
        {header: '번호', accessor: 'seq', width: '15%'},
        {header: '공사기간', accessor: 'constructDate', width: '15%'},
        {header: '업체명', accessor: 'companyName', width: '15%'},
        {header: '거주자', accessor: 'interiorConsumer', width: '15%'},
        {header: '현장명', accessor: 'title', width: '15%'},
        {header: '총 공사금액', accessor: 'price', width: '15%'},
        {header: '처리상태', accessor: 'status', width: '10%'},
    ], []);

    const data = useMemo(() => constructionData?.data.length > 0 ? constructionData?.data.map((i: constructionTypes) => {
        const convertStatus = () => {
            if (i.status === 1) return <Tag theme={tagThemes.navy} size={sizes.lg}>공사 중</Tag>;
            if (i.status === 2) return <Tag theme={tagThemes.navy} size={sizes.lg}>공사완료</Tag>;
        }

        return {
            ...i,
            constructDate: <>{i.startDate?.slice(0, 11)}~<br/>{i.endDate?.slice(0, 11)}</>,
            price: `${i.price?.toLocaleString()}원`,
            status: convertStatus()
        };
    }) : [], [constructionData]);

    return (
        <ConstructionWrap>
            <LayoutTemplate>
                <LayoutTitleTemplate title={'공사현장'}/>
                <TableHeaderWrap>
                    <IconInput placeholder={'검색어를 입력해주세요.'} fontStyle={'medium16'} size={sizes.sm} theme={inputThemes.white} width={'400px'}
                               onChange={(e) => setSearch(e?.target.value || '')}
                               icon={
                                   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                        stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                       <circle cx="11" cy="11" r="8"></circle>
                                       <path d="m21 21-4.3-4.3"></path>
                                   </svg>
                               }
                               onClick={() => navigate(`?page=${page || 1}&search=${search}`)}
                    />
                    <Button theme={buttonThemes.blue} shape={shapes.rectangle} fontStyle={'bold24'} width={'150px'}
                            onClick={() => navigate('/construction/add')}>
                        현장등록
                    </Button>
                </TableHeaderWrap>
                <Table columns={columns} data={data} onClick={(i) => navigate(`/construction/detail/${i.seq}`)}/>
                {
                    constructionData && <Pagination count={constructionData?.count} page={Number(page)} search={search}/>
                }
            </LayoutTemplate>
        </ConstructionWrap>
    );
};

const ConstructionWrap = styled.div`

`

const TableHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
