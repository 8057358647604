import styled from "styled-components";
import Input from "../../atoms/input";
import Button from "../../atoms/button";
import logo from '../../../assets/images/logo.png';
import {useLogin} from "../../../api/user";
import {ChangeEvent, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";

export const LoginTemplate = () => {
    const navigate = useNavigate();
    const {mutate} = useLogin();
    const [info, setInfo] = useState({id: '', password: ''});

    const onChangeInfo = useCallback((key: string, e?: ChangeEvent<HTMLInputElement>) => {
        const value = e?.target.value;

        setInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.id && !info.password) {
            alert('아이디와 패스워드를 입력해주세요.');
            return;
        }

        mutate(info, {
            onSuccess: async () => {
                navigate('/request');
            },
        });
    }, [info]);

    return (
        <LoginWrap>
            <Logo src={logo} alt={'logo'}/>
            <span>정비서 관리자 로그인</span>

            <LoginInputWrap>
                <Input placeholder={'아이디 입력'} width={'100%'} onChange={(e) => onChangeInfo('id', e)}/>
                <Input type={'password'} placeholder={'비밀번호 입력'} width={'100%'} onChange={(e) => onChangeInfo('password', e)}/>
            </LoginInputWrap>
            <Button width={'100%'} fontStyle={'medium24'} onClick={onSubmit}>
                로그인
            </Button>
        </LoginWrap>
    )
};

const LoginWrap = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  margin: 0 auto;
  gap: 20px;

  & > span {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
  }
`

const Logo = styled.img`
  max-width: 140px;
`

const LoginInputWrap = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  padding: 40px 0;
  
  & input {
    font-size: 24px;
  }
`
