import styled from "styled-components";
import {useEffect, useState} from "react";
import pagination from "../../../utils/pagination";
import {colors} from "../../../styles";
import {useNavigate} from "react-router-dom";

type props = {
    count: number,
    page: number,
    search?: string,
}

const Pagination = ({count, page, search}: props) => {
    const navigate = useNavigate();
    const [paginate, setPaginate] = useState(pagination(count, Number(page) || 1));

    useEffect(() => {
        setPaginate(pagination(count, Number(page) || 1));
    }, [count, page, search])

    return (
        <PaginationWrap>
            {
                !paginate.preventPrevPage &&
                <>
                    <PaginationPrevNextWrap>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                             onClick={() => navigate(`?page=${paginate.bigPrevPage}&search=${search || ''}`)}
                             strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="11 17 6 12 11 7"></polyline>
                            <polyline points="18 17 13 12 18 7"></polyline>
                        </svg>
                    </PaginationPrevNextWrap>
                    <PaginationPrevNextWrap>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             onClick={() => navigate(`?page=${Number(page) - 1}&search=${search || ''}`)}
                             stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-chevron-left">
                            <polyline points="15 18 9 12 15 6"/>
                        </svg>
                    </PaginationPrevNextWrap>
                </>
            }

            {
                paginate.pageBlock.map((i, index) => {
                    return (
                        <PaginationGoToButton
                            onClick={() => navigate(`?page=${i}&search=${search || ''}`)}
                            $active={(Number(page) || 1) === i}
                            key={index}> {i} </PaginationGoToButton>
                    )
                })
            }

            {
                !paginate.preventNextPage &&
                <>
                    <PaginationPrevNextWrap>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             onClick={() => navigate(`?page=${Number(page) + 1}&search=${search || ''}`)}
                             stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"/>
                        </svg>
                    </PaginationPrevNextWrap>
                    <PaginationPrevNextWrap>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                             onClick={() => navigate(`?page=${paginate.bigNextPage}&search=${search || ''}`)}
                             strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                            <polyline points="13 17 18 12 13 7"></polyline>
                            <polyline points="6 17 11 12 6 7"></polyline>
                        </svg>
                    </PaginationPrevNextWrap>
                </>
            }
        </PaginationWrap>
    )
}

const PaginationWrap = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  & label {
    padding: 4px 7px;
  }

  & label.active {
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 6px;
    box-shadow: 0 3px 20px #0000000b;
  }

  @media screen and (max-width: 768px) {
    & {
      gap: 2px;
    }
  }
`

const PaginationGoToButton = styled.button<{ $active: boolean }>`
  color: ${({$active}) => $active ? colors.white : ''};
  background-color: ${({$active}) => $active ? colors.main : ''};
  width: 40px;
  height: 40px;
  font-size: 24px;
  border-radius: 100%;
`

const PaginationPrevNextWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.gray};
  border-radius: 100%;
  width: 40px;
  height: 40px;

  & svg {
    cursor: pointer;
  }
`

export default Pagination;
