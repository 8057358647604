import styled from "styled-components";
import {useCallback, useEffect, useMemo, useState} from "react";
import {LayoutTemplate} from "../layout";
import {LayoutTitleTemplate} from "../layout/title";
import Input, {inputThemes} from "../../atoms/input";
import Table from "../../atoms/table";
import Pagination from "../../atoms/table/pagination";
import IconInput from "../../molecules/iconinput";
import {useNavigate} from "react-router-dom";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {useDeleteUser, useFindUser, useFindUsers, useUpdateUser, useUpdateUserPin} from "../../../api/user";
import Button, {buttonThemes} from "../../atoms/button";
import {modalState, showModal} from "../../../recoil/modal";
import {useSetRecoilState} from "recoil";
import {colors} from "../../../styles";
import {userTypes} from "../../../types/user";
import {useQueryClient} from "react-query";
import {shapes, sizes} from "../../../types";

const UserDetailModal = ({seq}: userTypes) => {
    const queryClient = useQueryClient();
    const [isModify, setIsModify] = useState(false);
    const {mutate: updateUserPinMutate} = useUpdateUserPin();
    const {mutate: updateUserMutate} = useUpdateUser();
    const {data: userData} = useFindUser({seq});
    const [info, setInfo] = useState({
        seq: 0,
        id: '',
        name: '',
        accountBank: '',
        accountNumber: '',
        telephone: '',
        companyName: '',
    });

    const onChangeInfo = useCallback((key: string, value: string | number) => {
        setInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (isModify) {
            updateUserMutate(info, {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['userDetail']);
                    setIsModify(false);
                },
            });
        } else {
            setIsModify(true);
        }
    }, [info, isModify]);

    const onSubmitPin = useCallback(() => {
        updateUserPinMutate(info, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['userDetail']);
                setIsModify(false);
            },
        });
    }, [info, isModify]);

    useEffect(() => {
        if (userData) setInfo({
            seq: userData.data.seq,
            id: userData.data.id,
            name: userData.data.name,
            accountBank: userData.data.accountBank,
            accountNumber: userData.data.accountNumber,
            telephone: userData.data.telephone,
            companyName: userData.data.companyName,
        });
    }, [userData]);

    if (userData) return (
        <UserDetailModalWrap>
            <h1>
                {userData.data.companyName}
            </h1>

            <UserDetailModalItemsWrap>
                <UserDetailModalItemWrap>
                    <span>업체명</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm} value={userData.data.companyName}
                                   width={'100%'} onChange={(e) => onChangeInfo('companyName', e?.target.value || '')}/> :
                            <span>{userData.data.companyName}</span>
                    }
                </UserDetailModalItemWrap>

                <UserDetailModalItemWrap>
                    <span>아이디</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm} value={userData.data.id}
                                   width={'100%'} onChange={(e) => onChangeInfo('id', e?.target.value || '')}/> :
                            <span>{userData.data.id}</span>
                    }
                </UserDetailModalItemWrap>

                <UserDetailModalItemWrap>
                    <span>지급 비밀번호</span>
                    <span>
                        {userData.data.pin ? '등록' : '미등록'}
                        {
                            isModify &&
                            <Button theme={buttonThemes.blue} shape={shapes.rectangle} width={'80px'} onClick={onSubmitPin}>
                                초기화
                            </Button>
                        }
                    </span>
                </UserDetailModalItemWrap>

                <UserDetailModalItemWrap>
                    <span>대표자</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm} value={info.name}
                                   width={'100%'} onChange={(e) => onChangeInfo('name', e?.target.value || '')}/> :
                            <span>{userData.data.name}</span>
                    }
                </UserDetailModalItemWrap>

                <UserDetailModalItemWrap>
                    <span>연락처</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm} value={info.telephone}
                                   width={'100%'} onChange={(e) => onChangeInfo('telephone', e?.target.value || '')}/> :
                            <span>{userData.data.telephone}</span>
                    }
                </UserDetailModalItemWrap>

                <UserDetailModalItemWrap>
                    <span>계좌정보</span>
                    {
                        isModify ?
                            <span>
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm} value={info.accountBank}
                                       width={'100%'} onChange={(e) => onChangeInfo('accountBank', e?.target.value || '')}/>
                                -
                                <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm} value={info.accountNumber}
                                       width={'100%'} onChange={(e) => onChangeInfo('accountNumber', e?.target.value || '')}/>
                            </span>
                            :
                            <span>{userData.data.accountBank} - {userData.data.accountNumber}</span>
                    }
                </UserDetailModalItemWrap>
            </UserDetailModalItemsWrap>

            <Button theme={buttonThemes.blue} shape={shapes.rectangle} fontStyle={'bold24'} width={'150px'} onClick={onSubmit}>
                수정하기
            </Button>
        </UserDetailModalWrap>
    )
    else return <></>
};

export const UserTemplate = () => {
    const navigate = useNavigate();
    const {page} = useGetQueryString();
    const {data: userData} = useFindUsers();
    const {mutate: deleteUserMutate} = useDeleteUser();
    const queryClient = useQueryClient()
    const [search, setSearch] = useState('');
    const setModal = useSetRecoilState(modalState);

    const onDelete = useCallback((seq?: number) => {
        if (window.confirm("회원을 삭제하시겠습니까?")) {
            deleteUserMutate({seq}, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['user'])
                }
            });
        }
    }, []);

    const columns = useMemo(() => [
        {header: '번호', accessor: 'seq', width: '10%'},
        {header: '아이디', accessor: 'id', width: '20%'},
        {header: '업체명', accessor: 'companyName', width: '20%'},
        {header: '대표자', accessor: 'name', width: '20%'},
        {header: '연락처', accessor: 'telephone', width: '20%'},
        {header: '', accessor: 'action', width: '10%'},
    ], []);

    const data = useMemo(() => userData?.data.length > 0 ? userData?.data.map((i: userTypes) => {
        return {
            ...i,
            action:
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" onClick={() => onDelete(i.seq)}
                     strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2">
                    <path d="M3 6h18"/>
                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                    <line x1="10" x2="10" y1="11" y2="17"/>
                    <line x1="14" x2="14" y1="11" y2="17"/>
                </svg>
        };
    }) : [], [userData]);

    return (
        <UserWrap>
            <LayoutTemplate>
                <>
                    <LayoutTitleTemplate title={'회원목록'}/>
                    <TableHeaderWrap>
                        <IconInput placeholder={'검색어를 입력해주세요.'} fontStyle={'medium16'} size={sizes.sm} theme={inputThemes.white} width={'400px'}
                                   onChange={(e) => setSearch(e?.target.value || '')}
                                   icon={
                                       <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                            stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                           <circle cx="11" cy="11" r="8"></circle>
                                           <path d="m21 21-4.3-4.3"></path>
                                       </svg>
                                   }
                                   onClick={() => navigate(`?page=${page || 1}&search=${search}`)}
                        />
                        <Button theme={buttonThemes.blue} shape={shapes.rectangle} fontStyle={'bold24'} width={'150px'}
                                onClick={() => navigate('/user/add')}>
                            회원등록
                        </Button>
                    </TableHeaderWrap>
                    <Table columns={columns} data={data}
                           onClick={(i) => showModal(setModal, {children: <UserDetailModal seq={i.seq}/>})}/>
                    {
                        userData && <Pagination count={userData?.count} page={Number(page)} search={search}/>
                    }
                </>
            </LayoutTemplate>
        </UserWrap>
    );
};

const UserWrap = styled.div`

`

const TableHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const UserDetailModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 100px;

  & > h1 {
    border-bottom: 4px solid ${colors.main};
    padding: 10px 0;
    max-width: 450px;
    width: 100%;
  }

  & > button {
    align-self: end;
  }
`

const UserDetailModalItemsWrap = styled.div`
  border: 2px solid ${colors.main};
  border-radius: 16px;
  padding: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
`

const UserDetailModalItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  & span:first-child {
    color: ${colors.main}
  }

  & span:last-child {
    font-size: 20px;
    font-weight: bold;

    display: flex;
    align-items: center;
    gap: 20px;
  }

  & input {
    font-size: 20px;
    font-weight: bold;
  }
`
