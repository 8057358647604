import Input, {inputThemes} from "../../../atoms/input";
import Button, {buttonThemes} from "../../../atoms/button";
import styled from "styled-components";
import {colors} from "../../../../styles";
import {useCallback, useEffect, useState} from "react";
import {useQueryClient} from "react-query";
import {useFindConstruction, useUpdateConstruction} from "../../../../api/construction";
import {useParams} from "react-router-dom";
import CDatePickerRange from "../../../atoms/datepicker/pickerRange";
import {shapes, sizes} from "../../../../types";

export const ConstructionDetailHeaderTemplate = () => {
    const queryClient = useQueryClient();
    const {seq} = useParams();
    const {data: constructionData} = useFindConstruction({seq: Number(seq)});
    const {mutate: updateConstructionMutate} = useUpdateConstruction();
    const [isModify, setIsModify] = useState(false);
    const [info, setInfo] = useState({
        seq: 0,
        telephone: '',
        companyName: '',
        companyAddress: '',
        companyTelephone: '',
        consumerPrice: 0,
        price: 0,
        startDate: '',
        endDate: '',
        interiorConsumer: ''
    });

    const onPushComma = useCallback((value: string | number) => {
        if (value) {
            const numValue = value.toString().replaceAll(',', '');
            value = numValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            return value;
        }
    }, []);

    const onChangeInfo = useCallback((key: string, value: string | number) => {
        if (key === 'price' || key === 'consumerPrice') value = value.toString().replaceAll(',', '');

        setInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (isModify) {
            if (info.startDate > info.endDate) {
                alert('시작 날짜가 종료 날짜보다 클 수 없습니다.')
                return false;
            }

            if (info.price < Number(constructionData.usePrice) || info.consumerPrice < Number(constructionData.usePrice)) {
                alert('공사 금액이나 소비자에게 받은 금액보다 지급 금액이 클 수 없습니다.');
                return false;
            }

            const keys = Object.keys(info);
            for (let key of keys) {
                if (!info[key as keyof typeof info]) {
                    alert("모든 입력 값을 입력해주세요.");
                    return false;
                }
            }

            updateConstructionMutate(info, {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['constructionDetail']);
                    setIsModify(false);
                },
            });
        } else {
            setIsModify(true);
        }
    }, [info, isModify]);

    const onStatusUpdate = useCallback((status: number) => {
        updateConstructionMutate({seq: Number(seq), status}, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['constructionDetail']);
            },
        });
    }, []);

    useEffect(() => {
        if (constructionData) setInfo({
            seq: constructionData.seq,
            telephone: constructionData.telephone,
            companyName: constructionData.companyName,
            companyAddress: constructionData.companyAddress,
            companyTelephone: constructionData.companyTelephone,
            consumerPrice: constructionData.consumerPrice,
            price: constructionData.price,
            startDate: constructionData.startDate,
            endDate: constructionData.endDate,
            interiorConsumer: constructionData.interiorConsumer
        });
    }, [constructionData]);

    if (constructionData) return (
        <TableHeaderWrap>
            <TableHeaderLeftWrap>
                <TableHeaderLeftItemWrap>
                    <span>공사 날짜</span>
                    {
                        isModify ?
                            <CDatePickerRange size={sizes.sm} fontStyle={'bold20'}
                                              selected={info.startDate ? new Date(info.startDate) : undefined}
                                              startDate={info.startDate ? new Date(info.startDate) : undefined}
                                              endDate={info.endDate ? new Date(info.endDate) : undefined}
                                              onChange={(dates: [Date | null, Date | null]) => {
                                                  const [start, end] = dates;

                                                  if (!start && !end) {
                                                      onChangeInfo('startDate', '');
                                                      onChangeInfo('endDate', '');
                                                  } else {
                                                      onChangeInfo('startDate', start?.toISOString() || '');
                                                      onChangeInfo('endDate', end?.toISOString() || '');
                                                  }
                                              }}
                            />
                            :
                            <span>{constructionData.startDate?.slice(0, 11)}~ {constructionData.endDate?.slice(0, 11)}</span>
                    }
                </TableHeaderLeftItemWrap>

                <TableHeaderLeftItemWrap/>

                <TableHeaderLeftItemWrap>
                    <span>인테리어 소비자</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                   value={info.interiorConsumer}
                                   width={'100%'} onChange={(e) => onChangeInfo('interiorConsumer', e?.target.value || '')}/> :
                            <span>{constructionData.interiorConsumer}</span>
                    }
                </TableHeaderLeftItemWrap>

                <TableHeaderLeftItemWrap>
                    <span>연락처</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                   value={info.telephone}
                                   width={'100%'} onChange={(e) => onChangeInfo('telephone', e?.target.value || '')}/> :
                            <span>{constructionData.telephone}</span>
                    }
                </TableHeaderLeftItemWrap>

                <TableHeaderLeftItemWrap>
                    <span>관리 업체</span>
                    <span>{constructionData.companyName}</span>
                </TableHeaderLeftItemWrap>

                <TableHeaderLeftItemWrap>
                    <span>업체 연락처</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                   value={info.companyTelephone}
                                   width={'100%'} onChange={(e) => onChangeInfo('companyTelephone', e?.target.value || '')}/> :
                            <span>{constructionData.companyTelephone}</span>
                    }
                </TableHeaderLeftItemWrap>

                <TableHeaderLeftItemWrap>
                    <span>주소</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                   value={info.companyAddress}
                                   width={'100%'} onChange={(e) => onChangeInfo('companyAddress', e?.target.value || '')}/> :
                            <span>{constructionData.companyAddress}</span>
                    }
                </TableHeaderLeftItemWrap>

                <TableHeaderLeftItemButtonWrap>
                    <Button theme={constructionData.status === 1 ? buttonThemes.border : buttonThemes.redBorder} shape={shapes.rectangle}
                            fontStyle={'bold24'} width={'150px'} onClick={() => onStatusUpdate(constructionData.status === 1 ? 2 : 1)}>
                        {constructionData.status === 1 ? '공사진행' : '공사완료'}
                    </Button>
                    <Button theme={buttonThemes.blue} shape={shapes.rectangle} fontStyle={'bold24'} width={'150px'} onClick={onSubmit}>
                        수정하기
                    </Button>
                </TableHeaderLeftItemButtonWrap>
            </TableHeaderLeftWrap>

            <TableHeaderRightWrap>
                <TableHeaderRightItemWrap>
                    <span>총 공사 금액</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                   value={onPushComma(info.price)}
                                   width={'100%'} onChange={(e) => onChangeInfo('price', e?.target.value || '')}/> :
                            <span>{constructionData.price.toLocaleString()}원</span>
                    }
                </TableHeaderRightItemWrap>

                <TableHeaderRightItemWrap>
                    <span>소비자에게 받은 금액</span>
                    {
                        isModify ?
                            <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                   value={onPushComma(info.consumerPrice)}
                                   width={'100%'} onChange={(e) => onChangeInfo('consumerPrice', e?.target.value || '')}/> :
                            <span>{Number(constructionData.consumerPrice).toLocaleString()}원</span>
                    }
                </TableHeaderRightItemWrap>

                <TableHeaderRightItemWrap>
                    <span>지급 금액</span>
                    <span>{Number(constructionData.usePrice).toLocaleString()}원</span>
                </TableHeaderRightItemWrap>

                <TableHeaderRightItemWrap>
                    <span>사용 가능 금액</span>
                    <span>{Number(constructionData.usePossiblePrice).toLocaleString()}원</span>
                </TableHeaderRightItemWrap>
            </TableHeaderRightWrap>
        </TableHeaderWrap>
    )
    else return <></>;
}


const TableHeaderWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 80px;
`

const TableHeaderLeftWrap = styled.div`
  border: 2px solid ${colors.main};
  border-radius: 16px;
  width: 70%;
  padding: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  position: relative;
`

const TableHeaderLeftItemButtonWrap = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  display: flex;
  gap: 10px;
`

const TableHeaderRightWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TableHeaderRightItemWrap = styled.div`
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;

  & span:first-child {
    padding: 5px 0;
    margin-bottom: 5px;
    width: 100%;
    color: ${colors.main};
    border-bottom: 1px solid ${colors.main};
  }

  & span:last-child {
    text-align: end;
  }

  & input {
    font-size: 20px;
    font-weight: bold;
    text-align: end;
  }
`;

const TableHeaderLeftItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  & > span:last-child {
    font-size: 20px;
    font-weight: bold;
  }

  & > span:first-child {
    color: ${colors.main}
  }

  & input {
    font-size: 20px;
    font-weight: bold;
  }
`
