import styled from "styled-components";
import {useCallback, useMemo, useState} from "react";
import {LayoutTemplate} from "../layout";
import {LayoutTitleTemplate} from "../layout/title";
import Input, {inputThemes} from "../../atoms/input";
import Table from "../../atoms/table";
import {useDeleteRequest, useFindRequests, useUpdateRequest} from "../../../api/request";
import {requestTypes} from "../../../types/request";
import Tag, {tagThemes} from "../../atoms/tag";
import Pagination from "../../atoms/table/pagination";
import IconInput from "../../molecules/iconinput";
import {useNavigate} from "react-router-dom";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {colors} from "../../../styles";
import Button, {buttonThemes} from "../../atoms/button";
import {useQueryClient} from "react-query";
import {bank} from "../../../constants/bank";
import Select from "../../atoms/select";
import {shapes, sizes} from "../../../types";
import CDatePicker from "../../atoms/datepicker/picker";
import {formatDate} from "../../../utils/formatDate";

export const RequestTemplate = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {page} = useGetQueryString();
    const {data: requestData} = useFindRequests();
    const {mutate: deleteRequestMutate} = useDeleteRequest();
    const [search, setSearch] = useState('');
    const {mutate} = useUpdateRequest();
    const [info, setInfo] = useState({
        seq: 0,
        name: '',
        accountHolder: '',
        accountBank: '',
        accountNumber: '',
        completeMemo: '',
        completeDate: ''
    });

    const onChangeInfo = useCallback((key: string, value: string | number) => {
        setInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        const keys = Object.keys(info);
        for (let key of keys) {
            if (!info[key as keyof typeof info]) {
                alert("모든 입력 값을 입력해주세요.");
                return false;
            }
        }

        mutate({...info, status: 1}, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['request']);
            },
        });
    }, [info]);

    const onDelete = useCallback((seq?: number) => {
        if (window.confirm("요청을 삭제하시겠습니까?")) {
            deleteRequestMutate({seq}, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['request'])
                }
            });
        }
    }, []);

    const columns = useMemo(() => [
        {header: '번호', accessor: 'seq', width: '10%'},
        {header: '카테고리', accessor: 'type', width: '10%'},
        {header: '현장명', accessor: 'title', width: '15%'},
        {header: '업체명', accessor: 'companyName', width: '15%'},
        {header: '신청일', accessor: 'insertDate', width: '15%'},
        {header: '연락처', accessor: 'telephone', width: '15%'},
        {header: '요청금액', accessor: 'price', width: '15%'},
        {header: '', accessor: 'status', width: '10%'},
        {header: '', accessor: 'action', width: '10%'},
    ], []);

    const data = useMemo(() => requestData?.data.length > 0 ? requestData?.data.map((i: requestTypes) => {
        const convertType = () => {
            if (i.type === 1) return '인건비';
            if (i.type === 2) return '자재비';
            if (i.type === 3) return '정산';
            if (i.type === 4) return '기타비용';
        }

        const convertStatus = () => {
            if (i.status === -1) return <Tag theme={tagThemes.red} size={sizes.lg}>취소</Tag>;
            if (i.status === 0) return <Tag theme={tagThemes.navy} size={sizes.lg}>대기</Tag>;
            if (i.status === 1) return <Tag theme={tagThemes.green} size={sizes.lg}>처리</Tag>;
        }

        return {
            ...i,
            type: convertType(),
            insertDate: i.insertDate?.slice(5, 16),
            price: `${i.price?.toLocaleString()}원`,
            status: convertStatus(),
            action:
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2"
                     onClick={() => onDelete(i.seq)}
                     strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2">
                    <path d="M3 6h18"/>
                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                    <line x1="10" x2="10" y1="11" y2="17"/>
                    <line x1="14" x2="14" y1="11" y2="17"/>
                </svg>
        };
    }) : [], [requestData]);

    return (
        <RequestWrap>
            <LayoutTemplate>
                <LayoutTitleTemplate title={'대기 중 요청'}/>
                <IconInput placeholder={'검색어를 입력해주세요.'} fontStyle={'medium16'} size={sizes.sm} theme={inputThemes.white} width={'400px'}
                           onChange={(e) => setSearch(e?.target.value || '')}
                           icon={
                               <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                    stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                   <circle cx="11" cy="11" r="8"></circle>
                                   <path d="m21 21-4.3-4.3"></path>
                               </svg>
                           }
                           onClick={() => navigate(`?page=${page || 1}&search=${search}`)}
                />
                <Table columns={columns} data={data} selectValue={info.seq} setSelectValue={(e) => onChangeInfo('seq', e.seq)}
                       onChildren={(i) =>
                           <InputWrap key={i.seq}>
                               <InputItemsWrap>
                                   <InputItemWrap>
                                       <span>이름</span>
                                       <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                              placeholder={'이름을 입력해주세요.'}
                                              width={'100%'} onChange={(e) => onChangeInfo('name', e?.target.value || '')}/>
                                   </InputItemWrap>

                                   <InputItemWrap>
                                       <span>예금주</span>
                                       <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                              placeholder={'예금주 이름을 입력해주세요.'} width={'100%'}
                                              onChange={(e) => onChangeInfo('accountHolder', e?.target.value || '')}/>
                                   </InputItemWrap>

                                   <InputItemWrap>
                                       <span>계좌번호</span>

                                       <InputItemMultiWrap>
                                           <Select infoKey={'accountBank'} setInfo={setInfo} items={bank} placeholder={'은행선택'}/>
                                           <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.sm}
                                                  placeholder={'계좌번호를 입력해주세요.'} width={'100%'}
                                                  onChange={(e) => onChangeInfo('accountNumber', e?.target.value || '')}/>
                                       </InputItemMultiWrap>
                                   </InputItemWrap>

                                   <InputItemWrap>
                                       <span>처리날짜</span>
                                       <CDatePicker selected={info.completeDate ? new Date(info.completeDate) : undefined}
                                                    onChange={(date) => onChangeInfo('completeDate', formatDate({source: date, showTime: true}))}
                                                    size={sizes.sm}/>
                                   </InputItemWrap>
                               </InputItemsWrap>

                               <InputItemWrap>
                                   <span>처리내용</span>
                                   <Input theme={inputThemes.plain} shape={shapes.rectangle} size={sizes.lg} placeholder={'처리내용을 입력해주세요.'}
                                          width={'100%'} onChange={(e) => onChangeInfo('completeMemo', e?.target.value || '')}/>
                               </InputItemWrap>

                               <Button width={'150px'} fontStyle={'medium24'} theme={buttonThemes.blue} shape={shapes.rectangle}
                                       onClick={onSubmit}>
                                   처리완료
                               </Button>
                           </InputWrap>
                       }
                >
                </Table>
                {
                    requestData && <Pagination count={requestData?.count} page={Number(page)} search={search}/>
                }
            </LayoutTemplate>
        </RequestWrap>
    );
};

const RequestWrap = styled.div`

`

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px;
  align-items: end;
`

const InputItemsWrap = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(4, 1fr);
`

const InputItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
  width: 100%;

  & > span {
    color: ${colors.main}
  }

  & input {
    font-size: 16px;
  }
`;

const InputItemMultiWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
