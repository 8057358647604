import {UserAddTemplate} from "../../components/templates/user/add";
import styled from "styled-components";

export const UserAdd = () => {
    return (
        <UserAddWrap>
            <UserAddTemplate></UserAddTemplate>
        </UserAddWrap>
    )
};

const UserAddWrap = styled.div`
    
`
