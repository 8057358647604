import styled from "styled-components";

export const Privacy = () => {
        return (
            <PrivacyWrap>
                <br/>스페이스플래닝(주)('com.jungbiser'이하 '정비서')은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
                <br/>수립·공개합니다.
                <br/>
                <br/>○ 이 개인정보처리방침은 2023년 8월 1부터 적용됩니다.
                <br/>
                <br/>제1조(개인정보의 처리 목적)
                <br/>
                <br/>스페이스플래닝(주)('com.jungbiser'이하 '정비서')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에
                <br/>따라 별도의
                <br/>동의를 받는 등 필요한 조치를 이행할 예정입니다.
                <br/>
                <br/>1. 홈페이지 회원가입 및 관리
                <br/>
                <br/>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>2. 민원사무 처리
                <br/>
                <br/>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                <br/>3. 재화 또는 서비스 제공
                <br/>
                <br/>서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증을 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                <br/>4. 마케팅 및 광고에의 활용
                <br/>
                <br/>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                <br/>제2조(개인정보의 처리 및 보유 기간)
                <br/>
                <br/>① 스페이스플래닝(주)은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
                <br/>
                <br/>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                <br/>
                <br/>1.홈페이지 회원가입 및 관리
                <br/>홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터
                <br/>
                <br/>5년 까지 위 이용목적을 위하여 보유.이용됩니다.
                <br/>보유근거 : 개인정보보호법
                <br/>관련법령 : 1)계약 또는 청약철회 등에 관한 기록 : 5년
                <br/>2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                <br/>3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                <br/>4) 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
                <br/>예외사유 : 이용자 파기 요청 시
                <br/>
                <br/>
                <br/>제3조(처리하는 개인정보의 항목)
                <br/>
                <br/>① 스페이스플래닝(주)은(는) 다음의 개인정보 항목을 처리하고 있습니다.
                <br/>
                <br/>1 민원사무 처리
                <br/>필수항목 : 이름, 생년월일, 로그인ID, 비밀번호, 이메일, 회사명, 회사전화번호, 접속 IP 정보, 쿠키, 접속 로그, 서비스 이용 기록
                <br/>선택항목 :
                <br/>
                <br/>
                <br/>제4조(만 14세 미만 아동의 개인정보 처리에 관한 사항)
                <br/>
                <br/>
                <br/>① 개인정보처리자명 은(는) 만 14세 미만 아동에 대해 개인정보를 수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인정보를 수집합니다.
                <br/>
                <br/>• 필수항목 : 법정 대리인의 성명, 관계, 연락처
                <br/>
                <br/>② 또한, 개인정보처리자명 의 처리목적 관련 홍보를 위해 아동의 개인정보를 수집할 경우에는 법정대리인으로부터 별도의 동의를 얻습니다.
                <br/>
                <br/>③ 개인정보처리자명 은(는) 만 1 4세 미만 아동의 개인정보를 수집할 때에는 아동에게 법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 다음 중 하나의 방법으로 적법한 법정대리인이 동의하였는지를 확인합니다.
                <br/>
                <br/>• 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 개인정보처리자가 그 동의 표시를 확인했음을 법정대리인의 휴대전화 문자 메시지로 알리는 방법
                <br/>
                <br/>• 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 신용카드·직불카드 등의 카드정보를 제공받는 방법
                <br/>
                <br/>• 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인 여부를 확인하는 방법
                <br/>
                <br/>• 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편 또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인 후 제출하도록 하는 방법
                <br/>
                <br/>• 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송받는 방법
                <br/>
                <br/>• 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나 인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화 통화를 통하여 동의를 얻는 방법
                <br/>
                <br/>• 그 밖에 위와 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를 확인하는 방법
                <br/>
                <br/>
                <br/>제5조(개인정보의 제3자 제공에 관한 사항)
                <br/>
                <br/>① 스페이스플래닝(주) 은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                <br/>
                <br/>② 스페이스플래닝(주) 은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                <br/>
                <br/>1. 스페이스플래닝(주)
                <br/>개인정보를 제공받는 자 : 스페이스플래닝(주)
                <br/>제공받는 자의 개인정보 이용목적 : 이름, 로그인ID, 비밀번호, 휴대전화번호, 회사명, 쿠키, 접속 로그, 서비스 이용 기록
                <br/>제공받는 자의 보유.이용기간: 5년
                <br/>
                <br/>
                <br/>제6조(개인정보처리의 위탁에 관한 사항)
                <br/>
                <br/>① 스페이스플래닝(주) 은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                <br/>
                <br/>1.
                <br/>위탁받는 자 (수탁자) :
                <br/>위탁하는 업무의 내용 :
                <br/>위탁기간 :
                <br/>② 스페이스플래닝(주) 은(는) 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
                <br/>명시하고, 수탁자가
                <br/>개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                <br/>
                <br/>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                <br/>
                <br/>
                <br/>제7조(개인정보의 파기절차 및 파기방법)
                <br/>
                <br/>
                <br/>① 스페이스플래닝(주) 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
                <br/>
                <br/>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                <br/>1. 법령 근거 :
                <br/>2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜
                <br/>
                <br/>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                <br/>1. 파기절차
                <br/>스페이스플래닝(주) 은(는) 파기 사유가 발생한 개인정보를 선정하고, 스페이스플래닝(주) 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                <br/>
                <br/>2. 파기방법
                <br/>
                <br/>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                <br/>
                <br/>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
                <br/>
                <br/>
                <br/>제8조(미이용자의 개인정보 파기 등에 관한 조치)
                <br/>
                <br/>
                <br/>① 개인정보처리자명 은(는) 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체없이 파기합니다.
                <br/>② 개인정보처리자명 은(는) 휴먼전환 30일 전까지 휴면예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
                <br/>③ 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.
                <br/>
                <br/>
                <br/>제9조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
                <br/>
                <br/>
                <br/>① 정보주체는 스페이스플래닝(주)에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                <br/>
                <br/>② 제1항에 따른 권리 행사는스페이스플래닝(주)에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 스페이스플래닝(주)은(는) 이에 대해 지체 없이 조치하겠습니다.
                <br/>
                <br/>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                <br/>
                <br/>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
                <br/>
                <br/>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                <br/>
                <br/>⑥ 스페이스플래닝(주)은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                <br/>
                <br/>
                <br/>제10조(개인정보의 안전성 확보조치에 관한 사항)
                <br/>
                <br/>스페이스플래닝(주) 은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
                <br/>
                <br/>1. 개인정보 취급 직원의 최소화 및 교육
                <br/>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                <br/>
                <br/>2. 개인정보에 대한 접근 제한
                <br/>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                <br/>
                <br/>3. 접속기록의 보관 및 위변조 방지
                <br/>개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.
                <br/>또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
                <br/>
                <br/>4. 개인정보의 암호화
                <br/>이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                <br/>
                <br/>5. 비인가자에 대한 출입 통제
                <br/>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                <br/>
                <br/>
                <br/>제11조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)
                <br/>
                <br/>
                <br/>스페이스플래닝(주) 은(는) 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.
                <br/>
                <br/>
                <br/>제12조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)
                <br/>
                <br/>
                <br/>① 개인정보처리자 은(는) 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를 수집·이용하고 있습니다.
                <br/>
                <br/>② 개인정보처리자 은(는) 다음과 같이 행태정보를 수집합니다.
                <br/>
                <br/>12. 행태정보의 수집·이용·제공 및 거부 등에 관한 사항 제공을 위해 수집하는 행태정보의 항목, 행태정보 수집 방법, 행태정보 수집 목적, 보유·이용기간 및 이후 정보처리 방법을 입력하기 위한 표입니다.
                <br/>수집하는 행태정보의 항목 행태정보 수집 방법 행태정보 수집 목적 보유·이용기간 및 이후 정보처리 방법
                <br/>앱 서비스 로그인 이력, 실행 이력, 기입 데이터 앱방문/실행시 자동 수집 향후 서비스 프로세스 개선에 적용 수집일로부터 1년 뒤 파기
                <br/>온라인 맞춤형 광고 등을 위해 제3자(온라인 광고사업자 등)가 이용자의 행태정보를 수집·처리할수 있도록 허용한 경우
                <br/>③ 개인정보처리자 은(는) 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집·처리하도록 허용하고 있습니다.
                <br/>
                <br/>- 행태정보를 수집 및 처리하려는 광고 사업자 : ○○○, ○○○, ○○○, ○○○,
                <br/>
                <br/>- 행태정보 수집 방법 : 이용자가 당사 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및 전송
                <br/>
                <br/>- 수집·처리되는 행태정보 항목 : 이용자의 웹/앱 방문이력, 검색이력, 구매이력
                <br/>
                <br/>- 보유·이용기간 : 00일
                <br/>
                <br/>④ 개인정보처리자 은(는) 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지
                <br/>않습니다.
                <br/>⑤ 개인정보처리자 은(는) 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인 서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를 제공하지 않습니다.
                <br/>
                <br/>⑥ 개인정보처리자 은(는) 모바일 앱에서 온라인 맞춤형 광고를 위하여 광고식별자를 수집·이용합니다. 정보주체는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수 있습니다.
                <br/>
                <br/>‣ 스마트폰의 광고식별자 차단/허용
                <br/>
                <br/>(1) (안드로이드) ① 설정 → ② 개인정보보호 → ③ 광고 → ③ 광고 ID 재설정 또는 광고ID 삭제
                <br/>
                <br/>(2) (아이폰) ① 설정 → ② 개인정보보호 → ③ 추적 → ④ 앱이 추적을 요청하도록 허용 끔
                <br/>
                <br/>※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.
                <br/>
                <br/>⑦ 정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.
                <br/>
                <br/>‣ 웹브라우저를 통한 맞춤형 광고 차단/허용
                <br/>
                <br/>(1) 인터넷 익스플로러(Windows 10용 Internet Explorer 11)
                <br/>
                <br/>- Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택
                <br/>
                <br/>- 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택
                <br/>
                <br/>(2) Microsoft Edge
                <br/>
                <br/>- Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.
                <br/>
                <br/>- 설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택합니다.
                <br/>
                <br/>- ‘InPrivate를 검색할 때 항상 ""엄격"" 추적 방지 사용’ 여부를 선택합니다.
                <br/>
                <br/>- 아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.
                <br/>
                <br/>(3) 크롬 브라우저
                <br/>
                <br/>- Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.
                <br/>
                <br/>- 설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.
                <br/>
                <br/>- 쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다.
                <br/>
                <br/>52 | 개인정보 처리방침 작성지침 일반
                <br/>
                <br/>⑧ 정보주체는 아래의 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.
                <br/>
                <br/>‣ 개인정보 보호 담당부서
                <br/>
                <br/>부서명 : ○○○ 팀
                <br/>
                <br/>담당자 : ○○○
                <br/>
                <br/>연락처 : 전화번호 , 이메일 , 팩스번호
                <br/>
                <br/>
                <br/>제13조(추가적인 이용·제공 판단기준)
                <br/>
                <br/>스페이스플래닝(주) 은(는) ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다. 이에 따라
                <br/>스페이스플래닝(주) 가(이)
                <br/>정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
                <br/>▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부
                <br/>
                <br/>▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부
                <br/>
                <br/>▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부
                <br/>
                <br/>▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
                <br/>
                <br/>※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함
                <br/>
                <br/>
                <br/>제14조(가명정보를 처리하는 경우 가명정보 처리에 관한 사항)
                <br/>
                <br/>스페이스플래닝(주) 은(는) 다음과 같은 목적으로 가명정보를 처리하고 있습니다.
                <br/>
                <br/>▶ 가명정보의 처리 목적
                <br/>
                <br/>- 직접작성 가능합니다.
                <br/>
                <br/>▶ 가명정보의 처리 및 보유기간
                <br/>
                <br/>- 직접작성 가능합니다.
                <br/>
                <br/>▶ 가명정보의 제3자 제공에 관한 사항(해당되는 경우에만 작성)
                <br/>
                <br/>- 직접작성 가능합니다.
                <br/>
                <br/>▶ 가명정보 처리의 위탁에 관한 사항(해당되는 경우에만 작성)
                <br/>
                <br/>- 직접작성 가능합니다.
                <br/>
                <br/>▶ 가명처리하는 개인정보의 항목
                <br/>
                <br/>- 직접작성 가능합니다.
                <br/>
                <br/>▶ 법 제28조의4(가명정보에 대한 안전조치 의무 등)에 따른 가명정보의 안전성 확보조치에 관한 사항
                <br/>
                <br/>- 직접작성 가능합니다.
                <br/>
                <br/>제15조 (개인정보 보호책임자에 관한 사항)
                <br/>
                <br/>① 스페이스플래닝(주) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                <br/>
                <br/>▶ 개인정보 보호책임자
                <br/>성명 :정현우
                <br/>직책 :총괄
                <br/>직급 :대표
                <br/>연락처 :062-225-2224, hahaha-mail@hanmail.net, 062-225-2227
                <br/>※ 개인정보 보호 담당부서로 연결됩니다.
                <br/>
                <br/>
                <br/>▶ 개인정보 보호 담당부서
                <br/>부서명 :개발팀
                <br/>담당자 :안홍선
                <br/>연락처 :062-225-2224, ahs9702@hanmail.net, 062-225-2227
                <br/>② 정보주체께서는 스페이스플래닝(주) 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 스페이스플래닝(주) 은(는) 정보주체의
                <br/>문의에 대해
                <br/>지체 없이 답변 및 처리해드릴 것입니다.
                <br/>
                <br/>
                <br/>제16조(국내대리인의 지정)
                <br/>
                <br/>정보주체는 ｢개인정보 보호법｣ 제39조의11에 따라 지정된 스페이스플래닝(주) 의 국내대리인에게 개인정보 관련 고충처리 등의 업무를 위하여 연락을 취할 수 있습니다. 스페이스플래닝(주) 은(는) 정보주체의 개인정보 관련 고충처리 등 개인정보
                <br/>보호책임자의 업무
                <br/>등을 신속하게 처리할 수 있도록 노력하겠습니다.
                <br/>
                <br/>▶ 스페이스플래닝(주) 은(는) ｢개인정보 보호법｣ 제39조의11에 따라 국내대리인을 지정하였습니다.
                <br/>
                <br/>- 국내대리인의 성명 : [대리인 성명_직접입력] (법인의 경우 법인명, 대표자의 성명)
                <br/>
                <br/>- 국내대리인의 주소 : [대리인 주소_직접입력] (법인의 경우 영업소 소재지)
                <br/>
                <br/>- 국내대리인의 전화번호 : [대리인 전화번호_직접입력]
                <br/>
                <br/>- 국내대리인의 전자우편 주소 : [대리인 전자우편_직접입력]
                <br/>
                <br/>제17조(개인정보의 열람청구를 접수·처리하는 부서)
                <br/>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
                <br/>스페이스플래닝(주) 은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
                <br/>
                <br/>▶ 개인정보 열람청구 접수·처리 부서
                <br/>부서명 : 총괄
                <br/>담당자 : 정현우
                <br/>연락처 : 062-225-2224, hahaha-mail@hanmail.net, 062-225-2227
                <br/>
                <br/>
                <br/>제18조(정보주체의 권익침해에 대한 구제방법)
                <br/>
                <br/>
                <br/>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
                <br/>
                <br/>1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
                <br/>2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
                <br/>3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
                <br/>4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
                <br/>
                <br/>「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는
                <br/>바에 따라
                <br/>행정심판을 청구할 수 있습니다.
                <br/>
                <br/>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.
                <br/>
                <br/>제19조(영상정보처리기기 운영·관리에 관한 사항)
                <br/>① 스페이스플래닝(주) 은(는) 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.
                <br/>
                <br/>1.영상정보처리기기 설치근거·목적 : 스페이스플래닝(주) 의
                <br/>
                <br/>2.설치 대수, 설치 위치, 촬영 범위 :
                <br/>설치대수 : 1 대
                <br/>설치위치 : 개발팀 사무실 입구
                <br/>촬영범위 : 개발팀 내부 전체
                <br/>3.관리책임자, 담당부서 및 영상정보에 대한 접근권한자 : 정현우
                <br/>
                <br/>4.영상정보 촬영시간, 보관기간, 보관장소, 처리방법
                <br/>촬영시간 : 24 시간
                <br/>보관기간 : 촬영시부터 7일
                <br/>보관장소 및 처리방법 : 영상 자료 Database
                <br/>5.영상정보 확인 방법 및 장소 : 인공지능융합사업단 관리부서
                <br/>
                <br/>6.정보주체의 영상정보 열람등요구에 대한 조치 : 개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산 이익을 위해 필요한 경우에 한해 열람을 허용함
                <br/>
                <br/>7.영상정보처리기기 설치근거·목적 : 개인정보보호법 제25조(영상정보처리기 기의 설치․ 운영 제한) 제1항에 따라 시설안전 및 화재예방, 고 객의 안전을 위한 범죄 예방을 목적으로 영상정보처리기기를 설치 운영한다.
                <br/>
                <br/>
                <br/>제20조(개인정보 처리방침 변경)
                <br/>
                <br/>
                <br/>① 이 개인정보처리방침은 2023년 8월 1부터 적용됩니다.
                <br/>
                <br/>② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
                <br/>
                <br/>예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)
                <br/>
                <br/>예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)
                <br/>
                <br/>예시 ) - 20XX. X. X ~ 20XX. X. X 적용 (클릭)
            </PrivacyWrap>
        )
    }
;

const PrivacyWrap = styled.div`
  font-size: 14px;
  line-height: 40px;
  padding: 20px;
`
