type props = {
    source: Date | null,
    showTime?: boolean
}

export const formatDate = ({source, showTime}:props) => {
    const dateDelimiter = '-';
    const timeDelimiter = ':';

    if (source) {
        const year = source.getFullYear();
        const month = (source.getMonth() + 1).toString().padStart(2, '0');
        const day = source.getDate().toString().padStart(2, '0');
        const date = [year, month, day].join(dateDelimiter);

        const hour = source.getHours();
        const minute = source.getMinutes();
        const second = source.getSeconds();
        const time = [hour, minute, second].join(timeDelimiter);

        return showTime ? [date, time].join(' ') : date;
    } else {
        return '';
    }
}
