import styled from "styled-components";
import {colors} from "../../../styles";

type props = {
    date: Date,
    prevMonthButtonDisabled: boolean,
    nextMonthButtonDisabled: boolean,
    increaseMonth(): void,
    decreaseMonth(): void
}

export const CDatePickerHeader = ({date, prevMonthButtonDisabled, nextMonthButtonDisabled, decreaseMonth, increaseMonth}: props) => {
    return (
        <DatePickerHeaderWrap>
            <DatePickerHeaderDayWrap>
                <span>{date.getFullYear()}</span>
                <span>{date.getMonth() + 1}월</span>
            </DatePickerHeaderDayWrap>

            <DatePickerHeaderButtonWrap>
                    <span onClick={decreaseMonth} aria-disabled={prevMonthButtonDisabled}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                             strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left">
                            <polyline points="15 18 9 12 15 6"/>
                        </svg>
                    </span>

                <span onClick={increaseMonth} aria-disabled={nextMonthButtonDisabled}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                             strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"/>
                        </svg>
                    </span>
            </DatePickerHeaderButtonWrap>
        </DatePickerHeaderWrap>
    )
};


const DatePickerHeaderWrap = styled.div`
  display: flex;
  padding: 0 10px 10px 10px;
  font-size: 14px;
  justify-content: space-between;
  align-items: end;
`

const DatePickerHeaderDayWrap = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5px;

  & span:last-child {
    font-size: 24px;
    font-weight: bold;
    color: ${colors.main};
  }
`

const DatePickerHeaderButtonWrap = styled.div`
  display: flex;
  gap: 5px;

  & span {
    cursor: pointer;
    border: 1px solid ${colors.gray};
    padding: 3px;
    box-shadow: rgb(0 0 0 / 19%) 0 2px 4px 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
