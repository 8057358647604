import {RequestTemplate} from "../../components/templates/request";
import styled from "styled-components";

export const Request = () => {
    return (
        <RequestWrap>
            <RequestTemplate></RequestTemplate>
        </RequestWrap>
    )
};

const RequestWrap = styled.div`
    
`
