import axios from 'axios';
import {useMutation, useQuery} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {requestTypes} from "../types/request";
import {useParams} from "react-router-dom";

export const useFindRequestsByConstructionSeq = () => {
    const {seq} = useParams();
    const {page} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/request/list/${seq}`, {page});
    }, [page, seq]);

    return useQuery(
        ['requestByConstruction', {page, seq}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useFindRequests = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/request/list`, {page, search, status: 0});
    }, [page, search]);

    return useQuery(
        ['request', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useUpdateRequest = () => {
    const api = useCallback((data: requestTypes) => {
        return axios.put('/admin/api/v1/request/', data);
    }, []);

    return useMutation(
        async (data: requestTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteRequest = () => {
    const api = useCallback(({seq}: Pick<requestTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/request/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<requestTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data;
        },
    );
}
