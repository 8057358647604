import styled from "styled-components";
import {colors} from "../../../styles";
import {useFindDashboardCount} from "../../../api/dashboard";

type props = {
    title: string,
    isPriceShow?: boolean
}

export const LayoutTitleTemplate = ({title, isPriceShow = true}: props) => {
    const {data} = useFindDashboardCount();

    return (
        <LayoutTitleWrap>
            <h1>{title}</h1>

            {
                isPriceShow && data && data.data &&
                <LayoutTitleItemsWrap>
                    <LayoutTitleItemWrap>
                        <span>공사 중</span>
                        <hr/>
                        <strong>{data.data.constructionCount.count}건</strong>
                    </LayoutTitleItemWrap>

                    <LayoutTitleItemWrap>
                        <span>요청대기</span>
                        <hr/>
                        <strong>{data.data.requestCount.count}건</strong>
                    </LayoutTitleItemWrap>
                </LayoutTitleItemsWrap>
            }
        </LayoutTitleWrap>
    )
};

const LayoutTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  & > h1 {
    color: ${colors.main};
  }

  & > h1:before {
    content: '';
    height: 100%;
    padding-right: 12px;
    margin-right: 10px;
    background-color: ${colors.gray};
  }
`

const LayoutTitleItemsWrap = styled.div`
  display: flex;
  gap: 25px;
  margin-bottom: 100px;
`

const LayoutTitleItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  width: 180px;

  & > hr {
    background-color: ${colors.main};
    height: 4px;
  }

  & > strong {
    font-size: 20px;
  }
`
