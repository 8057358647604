import styled from "styled-components";
import {colors} from "../../../styles";
import {ReactNode, useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useLogout} from "../../../api/user";

export const LayoutTemplate = ({children}: { children: ReactNode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {mutate} = useLogout();

    const onLogout = useCallback(() => {
        mutate(undefined, {
            onSuccess: () => {
                navigate('/login');
            }
        })
    }, []);

    return (
        <LayoutWrap>
            <LayoutHeaderWrap>
                <LayoutHeaderItem $active={location.pathname === '/request'} onClick={() => navigate('/request')}>대기 중 요청</LayoutHeaderItem>
                <LayoutHeaderItem $active={location.pathname === '/construction/wait'} onClick={() => navigate('/construction/wait')}>현장 등록
                    관리</LayoutHeaderItem>
                <LayoutHeaderItem $active={location.pathname === '/construction'} onClick={() => navigate('/construction')}>공사 현장</LayoutHeaderItem>
                <LayoutHeaderItem $active={location.pathname === '/user'} onClick={() => navigate('/user')}>회원 목록</LayoutHeaderItem>
            </LayoutHeaderWrap>

            <LayoutContentWrap>
                {children}
            </LayoutContentWrap>

            <LayoutFloatWrap onClick={onLogout}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M13 4h3a2 2 0 0 1 2 2v14"></path>
                    <path d="M2 20h3"></path>
                    <path d="M13 20h9"></path>
                    <path d="M10 12v.01"></path>
                    <path d="M13 4.562v16.157a1 1 0 0 1-1.242.97L5 20V5.562a2 2 0 0 1 1.515-1.94l4-1A2 2 0 0 1 13 4.561Z"></path>
                </svg>
            </LayoutFloatWrap>
        </LayoutWrap>
    )
};

const LayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 1280px;
  padding: 50px 40px;
  margin: 0 auto;
`

const LayoutHeaderWrap = styled.ul`
  width: 100%;
  display: flex;
  gap: 25px;
  align-items: center;

  & > li {
    width: 100%;
    background-color: ${colors.main};
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-size: 24px;
    font-weight: bold;
  }
`

const LayoutHeaderItem = styled.li<{ $active: boolean }>`
  background-color: ${({$active}) => $active && `#002773 !important`};
  position: relative;
  cursor: pointer;

  ${({$active}) => $active && `
    &:before {
        content: '';
        position: absolute;
        background-color: #fff;
        width: 0;
        height: 0;
        border-bottom: 10px solid #fff;
        border-top: 10px solid #002773;
        border-left: 10px solid #002773;
        border-right: 10px solid #002773;
        bottom: 0;
        left: calc(50% - 10px);
    }
  `}
`

const LayoutContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const LayoutFloatWrap = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;
  background-color: ${colors.main};
  color: ${colors.white};
  padding: 10px;
  border-radius: 100%;
  cursor: pointer;
`
