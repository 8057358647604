import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {useEffect} from "react";
import {Login} from "../login";
import {User} from "../user";
import {UserAdd} from "../user/add";
import {Request} from "../request";
import {Construction} from "../construction";
import {ConstructionAdd} from "../construction/add";
import {ConstructionDetail} from "../construction/detail";
import {ConstructionWait} from "../construction/wait";
import {useLoginCheck} from "../../api/user";
import {Privacy} from "../privacy";

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {mutate} = useLoginCheck();

    useEffect(() => {
        if (location.pathname === '/login' || location.pathname === '/privacy') return;

        mutate(undefined, {
            onSuccess: async () => {

            },
            onError: async () => {
                if (location.pathname !== '/login') navigate('/login');
            }
        });
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="/user" element={<User/>}/>
            <Route path="/user/add" element={<UserAdd/>}/>

            <Route path="/request" element={<Request/>}/>

            <Route path="/construction" element={<Construction/>}/>
            <Route path="/construction/add" element={<ConstructionAdd/>}/>
            <Route path="/construction/detail/:seq" element={<ConstructionDetail/>}/>
            <Route path="/construction/wait" element={<ConstructionWait/>}/>

            <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
    );
}
