import styled from 'styled-components';
import {ReactNode} from "react";
import {colors, fontStyles, typography} from "../../../styles";
import {shapes} from "../../../types";

type props = {
    children?: ReactNode,
    width?: string;
    flex?: number;
    theme?: buttonThemes;
    shape?: shapes;
    fontStyle?: fontStyles;
    $isAble?: boolean;
    onClick?(): void;
}

type styledTypes = Pick<props, 'width' | 'theme' | '$isAble' | 'flex' | 'fontStyle' | 'shape'>;

export const buttonThemes = {
    normal: 'normal',
    blue: 'blue',
    skyblue: 'skyblue',
    border: 'border',
    redBorder: 'redBorder'
} as const;
export type buttonThemes = typeof buttonThemes[keyof typeof buttonThemes];

const Button = ({children, width, theme, shape, flex, $isAble = true, fontStyle, onClick}: props) => {
    return (
        <ShapeButtonWrap width={width} theme={theme} shape={shape} flex={flex} $isAble={$isAble} fontStyle={fontStyle}
                         onClick={$isAble ? onClick : () => {}}>
            {children}
        </ShapeButtonWrap>
    )
}

const ButtonWrap = styled.button<styledTypes>`
  ${({fontStyle}) => (fontStyle ? typography[fontStyle] : '')};
  ${({flex}) => (flex ? `flex: ${flex}` : '')};
  ${({width}) => (width ? `width: ${width}` : '')};
  opacity: ${({$isAble}) => $isAble ? 1 : 0.3};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
`;

const ThemeButtonWrap = styled(ButtonWrap)`
  ${({theme}) => {
    switch (theme) {
      case buttonThemes.redBorder:
        return `
            color: ${colors.red};
            background-color: #fff;
            border: 1px solid ${colors.red};
          `;
      case buttonThemes.border:
        return `
            color: ${colors.main};
            background-color: #fff;
            border: 1px solid ${colors.main};
          `;
      case buttonThemes.skyblue:
        return `
            color: ${colors.white};
            background-color: #6293F2;
          `;
      case buttonThemes.blue:
        return `
            color: ${colors.white};
            background-color: ${colors.main};
          `;
      default:
        return `
            color: ${colors.main};
            background-color: ${colors.white};
        `;
    }
  }};
`

const ShapeButtonWrap = styled(ThemeButtonWrap)`
  ${({shape}) => {
    switch (shape) {
      case shapes.rectangle:
        return `
            height: 48px;
            border-radius: 16px;
          `;
      default:
        return `
            height: 72px;
            border-radius: 80px;
        `;
    }
  }};
`

export default Button;
