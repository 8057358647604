import styled from "styled-components";
import DatePicker from "react-datepicker";
import ko from 'date-fns/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {CDatePickerHeader} from "./header";
import {CDatePickerInput} from "./input";
import {sizes} from "../../../types";
import {fontStyles} from "../../../styles";

type props = {
    selected?: Date,
    onChange(date: [Date | null, Date | null]): void,
    timeIntervals?: number,
    startDate?: Date,
    endDate?: Date,
    size?: sizes,
    fontStyle?: fontStyles
}

const CDatePickerRange = ({selected, startDate, endDate, onChange, timeIntervals = 10, size, fontStyle}: props) => {
    return (
        <DatePickerWrap selected={selected}
                        onChange={(dates: [Date | null, Date | null]) => onChange(dates)}
                        startDate={startDate}
                        endDate={endDate}
                        locale={ko}
                        timeIntervals={timeIntervals}
                        dateFormat={'yyyy-MM-dd'}
                        renderCustomHeader={({
                                                 date, prevMonthButtonDisabled, nextMonthButtonDisabled, increaseMonth, decreaseMonth
                                             }: { date: Date, prevMonthButtonDisabled: boolean, nextMonthButtonDisabled: boolean, increaseMonth(): void, decreaseMonth(): void }) => (
                            <CDatePickerHeader date={date}
                                               prevMonthButtonDisabled={prevMonthButtonDisabled}
                                               nextMonthButtonDisabled={nextMonthButtonDisabled}
                                               increaseMonth={increaseMonth}
                                               decreaseMonth={decreaseMonth}
                            />
                        )}
                        customInput={<CDatePickerInput size={size} fontStyle={fontStyle}/>}
                        selectsRange
                        onCalendarClose={() => {
                            if (!endDate) onChange([null, null]);
                        }}
        />
    );
}

const DatePickerWrap = styled(DatePicker)`
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 6px;
`

export default CDatePickerRange;
