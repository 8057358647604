import styled from "styled-components";
import {colors} from "../../../styles";
import {ReactNode} from "react";

type props = {
    columns: columnsType[],
    data: any[],
    children?: ReactNode,
    selectValue?: string | number,
    setSelectValue?(value: any): void,
    onChildren?(value: any): void
    onClick?(value: any): void
}

type columnsType = {
    header: string,
    accessor: string,
    width: string
}

const Table = ({
                   columns,
                   data,
                   children,
                   selectValue,
                   setSelectValue,
                   onChildren,
                   onClick
               }: props) => {
    return (
        <TableItemWrap>
            <TableHeaderWrap>
                {
                    columns.map((i, index) => {
                        return <TableHeaderItem key={index} width={i.width}>{i.header}</TableHeaderItem>
                    })
                }
            </TableHeaderWrap>

            <TableContentsWrap>
                {
                    data.length > 0 ? data.map((i, index) => {
                            return (
                                <>
                                    <TableContentWrap onClick={() => setSelectValue && setSelectValue(i)} key={index}>
                                        {
                                            columns.map((j, index) => (
                                                <TableContentItem width={j.width} key={index} onClick={() => (columns[index].accessor !== 'action' && onClick) && onClick(i)}>{i[columns[index].accessor]}</TableContentItem>
                                            ))
                                        }
                                    </TableContentWrap>

                                    {selectValue === i.seq && onChildren && onChildren(i)}
                                </>
                            );
                        }) :
                        <TableContentWrap>
                            <TableContentItem width={'100%'}>게시물이 존재하지 않습니다.</TableContentItem>
                        </TableContentWrap>
                }
            </TableContentsWrap>
        </TableItemWrap>
    )
}

const TableItemWrap = styled.div`

`

const TableHeaderWrap = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: ${colors.gray};
  color: ${colors.sub1};
  border-radius: 10px;
`

const TableHeaderItem = styled.div<{ width: string }>`
  width: ${({width}) => width};
  padding: 15px 5px;
  font-size: 20px;
  font-weight: bold;
`

const TableContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const TableContentWrap = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  background-color: #fff;
  border-radius: 6px;

  &:hover {
    background-color: #f7f7f8;
  }

  & div:first-child {
    color: ${colors.sub1};
    font-weight: bold;
  }
`

const TableContentItem = styled.div<{ width: string }>`
  width: ${({width}) => width};
  padding: 25px 5px;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0;
  }
`

export default Table;
