import styled from "styled-components";
import {useCallback, useMemo, useRef, useState} from "react";
import {LayoutTemplate} from "../../layout";
import {LayoutTitleTemplate} from "../../layout/title";
import {inputThemes} from "../../../atoms/input";
import Table from "../../../atoms/table";
import Tag, {tagThemes} from "../../../atoms/tag";
import Pagination from "../../../atoms/table/pagination";
import IconInput from "../../../molecules/iconinput";
import {useNavigate} from "react-router-dom";
import {useGetQueryString} from "../../../../utils/useGetQueryString";
import {useDeleteConstruction, useFindConstructionWait, useUpdateConstruction} from "../../../../api/construction";
import {colors} from "../../../../styles";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState, showModal} from "../../../../recoil/modal";
import {constructionTypes} from "../../../../types/construction";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import {useQueryClient} from "react-query";
import {sizes} from "../../../../types";
import {Document, Page, PageProps, pdfjs} from 'react-pdf';
import Modal from "../../../atoms/modal";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ConstructionWaitTemplate = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const {page} = useGetQueryString();
    const {data: constructionData} = useFindConstructionWait();
    const {mutate: deleteConstructionMutate} = useDeleteConstruction();
    const {mutate} = useUpdateConstruction();
    const [search, setSearch] = useState('');
    const slickRef = useRef<Slider>(null);
    const setModal = useSetRecoilState(modalState);

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0);

    const onDocumentLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    }, []);

    const onPrevious = useCallback(() => slickRef.current && slickRef.current.slickPrev(), []);
    const onNext = useCallback(() => slickRef.current && slickRef.current.slickNext(), []);

    const onPreviousPdf = useCallback(() => setPageNumber((prev) => {
        if (prev > 1) return prev - 1;
        else return prev;
    }), [pageNumber]);

    const onNextPdf = useCallback(() => setPageNumber((prev) => {
        if (pageNumber < numPages) return prev + 1;
        else return prev;
    }), [pageNumber, numPages]);

    const onSubmit = useCallback((seq?: number) => {
        if (window.confirm('현장 등록을 하시겠습니까?')) {
            mutate({seq, status: 1}, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['constructionWait'])
                }
            });
        }
    }, []);

    const onDelete = useCallback((seq?: number) => {
        if (window.confirm("현장을 삭제하시겠습니까?")) {
            deleteConstructionMutate({seq}, {
                onSuccess: () => {
                    queryClient.invalidateQueries(['constructionWait'])
                }
            });
        }
    }, []);

    const columns = useMemo(() => [
        {header: '번호', accessor: 'seq', width: '15%'},
        {header: '업체명', accessor: 'companyName', width: '20%'},
        {header: '신청일', accessor: 'insertDate', width: '20%'},
        {header: '연락처', accessor: 'companyTelephone', width: '20%'},
        {header: '첨부파일', accessor: 'imageUrl', width: '20%'},
        // {header: '', accessor: 'action1', width: '10%'},
        {header: '', accessor: 'action2', width: '5%'},
    ], []);

    const data = useMemo(() => constructionData?.data.length > 0 ? constructionData?.data.map((i: constructionTypes) => {
        return {
            ...i,
            insertDate: i.insertDate?.slice(5, 16),
            imageUrl:
                <ImageViewWrap onClick={() => {
                    setModalData(i);
                    showModal(setModal, {children: ''});
                }}>첨부파일보기
                </ImageViewWrap>,
            // action1: <Tag theme={tagThemes.navy} size={sizes.lg} onClick={() => onSubmit(i.seq)}>현장등록</Tag>,
            action2:
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth="2" onClick={() => onDelete(i.seq)}
                     strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2">
                    <path d="M3 6h18"/>
                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                    <line x1="10" x2="10" y1="11" y2="17"/>
                    <line x1="14" x2="14" y1="11" y2="17"/>
                </svg>
        };
    }) : [], [constructionData, pageNumber]);

    const [modalData, setModalData] = useState({});
    const modalContent = useCallback((i: constructionTypes) => {
        return (
            <SliderWrap>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                     onClick={i.imageUrl1?.indexOf('.pdf') === -1 ? onPrevious : onPreviousPdf}
                     stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                    <path d="m15 18-6-6 6-6"></path>
                </svg>
                {
                    i.imageUrl1?.indexOf('.pdf') === -1 ?
                        <Slider arrows={false} ref={slickRef}>
                            <ImageWrap>
                                <img src={i.imageUrl1} alt={''}></img>
                            </ImageWrap>
                            <ImageWrap>
                                <img src={i.imageUrl2} alt={''}></img>
                            </ImageWrap>
                        </Slider> :
                        <Document file={i.imageUrl1} onLoadSuccess={onDocumentLoadSuccess}>
                            <Slider arrows={false} ref={slickRef}>
                                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
                            </Slider>
                        </Document>
                }
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
                     onClick={i.imageUrl1?.indexOf('.pdf') === -1 ? onNext : onNextPdf}
                     stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                    <path d="m9 18 6-6-6-6"></path>
                </svg>
            </SliderWrap>
        )
    }, [pageNumber, numPages]);

    return (
        <ConstructionWaitWrap>
            <LayoutTemplate>
                <>
                    <LayoutTitleTemplate title={'현장 등록 관리'}/>
                    <IconInput placeholder={'검색어를 입력해주세요.'} fontStyle={'medium16'} size={sizes.sm} theme={inputThemes.white} width={'400px'}
                               onChange={(e) => setSearch(e?.target.value || '')}
                               icon={
                                   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                        stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                       <circle cx="11" cy="11" r="8"></circle>
                                       <path d="m21 21-4.3-4.3"></path>
                                   </svg>
                               }
                               onClick={() => navigate(`?page=${page || 1}&search=${search}`)}
                    />
                    <Table columns={columns} data={data}/>
                    {
                        constructionData && <Pagination count={constructionData?.count} page={Number(page)} search={search}/>
                    }

                    <Modal children={modalContent(modalData)}/>
                </>
            </LayoutTemplate>
        </ConstructionWaitWrap>
    );
};

const ConstructionWaitWrap = styled.div`

`

const ImageViewWrap = styled.span`
  text-decoration: underline;
  color: ${colors.sub1};
  font-weight: bold;
  cursor: pointer;
`

const SliderWrap = styled.div`
  width: 100%;
  position: relative;

  & svg {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 24px);
  }

  & svg:first-child {
    left: -75px;
  }

  & svg:last-child {
    right: -75px;
  }

  & .react-pdf__Document {
    width: 100%;
  }

  & .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
    max-height: calc(80vh - 200px);
    object-fit: contain;
  }
`

const ImageWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 35vw;

  & img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
