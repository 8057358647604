import axios from 'axios';
import {useMutation, useQuery} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {constructionTypes} from "../types/construction";

export const useInsertConstruction = () => {
    const api = useCallback((data: constructionTypes) => {
        return axios.post('/admin/api/v1/construction', data);
    }, []);

    return useMutation(
        async (data: constructionTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUpdateConstruction = () => {
    const api = useCallback((data: constructionTypes) => {
        return axios.put('/admin/api/v1/construction', data);
    }, []);

    return useMutation(
        async (data: constructionTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteConstruction = () => {
    const api = useCallback(({seq}: Pick<constructionTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/construction/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<constructionTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data;
        },
    );
}

export const useFindConstruction = ({seq}: constructionTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/construction/${seq}`);
    }, []);

    return useQuery(
        ['constructionDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindConstructions = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/construction/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['construction', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useFindConstructionWait = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/construction/wait/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['constructionWait', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
