import {ConstructionWaitTemplate} from "../../components/templates/construction/wait";
import styled from "styled-components";

export const ConstructionWait = () => {
    return (
        <ConstructionWaitWrap>
            <ConstructionWaitTemplate></ConstructionWaitTemplate>
        </ConstructionWaitWrap>
    )
};

const ConstructionWaitWrap = styled.div`
    
`
