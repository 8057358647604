import {UserTemplate} from "../../components/templates/user";
import styled from "styled-components";

export const User = () => {
    return (
        <UserWrap>
            <UserTemplate></UserTemplate>
        </UserWrap>
    )
};

const UserWrap = styled.div`
    
`
